import { useEffect, useState } from "react";
import { useCommonTitlesStore } from "../../../../../../../store/useStores";
import { Modal, Button, Tooltip } from "antd";
import Flex from "../../../../../../../helpers/components/Flex";
import useFunctionTags from "../hooks/useFunctionTags";

const FunctionsModal = ({
  visible,
  onCancel,
  addFunction,
  deleteFunction,
  editFunctionTagInfo,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const functionTags = useFunctionTags();

  const [functionID, setFunctionID] = useState("");

  const updateFunctionID = (id) => setFunctionID(id);

  useEffect(() => {
    setFunctionID(editFunctionTagInfo?.functionID);
  }, []);

  return (
    <Modal
      title={commonTitles["CO|1|ADDMTHFN"]}
      open={visible}
      onCancel={onCancel}
      width={600}
      style={{ marginTop: 40 }}
      destroyOnClose={true}
      footer={[
        <Button key="back" onClick={() => onCancel()}>
          {commonTitles["CO|1|CANCELBTN"]}
        </Button>,
        <Button
          key="delete"
          style={{ backgroundColor: "red", color: "white" }}
          onClick={() => deleteFunction(editFunctionTagInfo.index)}
        >
          {commonTitles["CO|1|DELETEBTN"]}
        </Button>,
        <Button
          key="submit"
          className="confirm-btn"
          style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
          onClick={() => addFunction(functionID, editFunctionTagInfo.index)}
        >
          {commonTitles["CO|1|ADDBTN"]}
        </Button>,
      ]}
    >
      <Flex
        flexDirection={"row"}
        style={{ gap: 8, paddingTop: 50, paddingBottom: 50 }}
      >
        {Object.entries(functionTags).map(([key, value]) => (
          <Tooltip key={key} placement="bottom" title={value.tooltip}>
            <Button
              key={key}
              style={{
                border: "dashed 0.5px #E5E4E2",
                backgroundColor: functionID === key ? "#6082B6" : "transparent",
                color: functionID === key && "#fff",
                fontSize: 12,
                heigth: 22,
                padding: 3,
                paddingTop: 0,
                paddingBottom: 0,
              }}
              onClick={() => updateFunctionID(key)}
            >
              {value.title}
            </Button>
          </Tooltip>
        ))}
      </Flex>
    </Modal>
  );
};

export default FunctionsModal;
