import Import from "./import/Import";
import Reports from "./reports/Reports";
import NewRegister from "./newRegister/NewRegister";
import Metrics from "./metrics/Metrics";
import { useCommonTitlesStore, useModuleStore } from "../../../store/useStores";
import Flex from "../../../helpers/components/Flex";
import htmlCodesRemover from "../../ultimateTable/helpers/htmlCodesRemover";
//import MetricsBackend from "./metrics-backend/MetricsBackend";
import BannerWrapper from "../../../helpers/components/BannerWrapper";
import BannerSearch from "../../../helpers/components/BannerSearch";
import EmailTrigger from "./emailTrigger/EmailTrigger";
import KPIsButton from "./kpis/KPIsButton";

const Banner = ({
  title = "",
  path = "",
  serverColumns = [],
  staticOptions = {},
  foreignKeyValues = {},
  //privileges = {},
  canView,
  canInsert,
  canUpdate,
  canDelete,
  insertData,
  moduleID,
  updateSearchValue,
  isProjectSpecific = true,
  // existingRowIDs = [],
  data = {},
  updateTotals,
  showEmailTriggerButton,
  kpis = [],
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const orderBy = useModuleStore((state) => state.orderBy);
  const orderDirection = useModuleStore((state) => state.orderDirection);

  let columnData = [];
  columnData =
    serverColumns
      ?.filter((field) => field.F_LIST === "Y")
      .map((field) => ({
        ...field,
        T_TITLE: htmlCodesRemover(field.T_TITLE) ?? field.TITLE,
      })) ?? [];

  let hasAnyUpdatePrivileges = false;
  let hasAnyInsertPrivileges = false;
  columnData.forEach((field) => {
    if (field?.canupdate || field?.canupdateifnull) {
      hasAnyUpdatePrivileges = true;
    }
    if (field?.caninsert) {
      hasAnyInsertPrivileges = true;
    }
  });

  const privileges = {
    canDelete: canDelete,
    canInsert: canInsert || hasAnyInsertPrivileges ? true : false,
    canCopy: canInsert,
    canUpdate: canUpdate || hasAnyUpdatePrivileges ? true : false,
    canView: canView,
  };

  return (
    <BannerWrapper title={title} path={commonTitles.data}>
      <Metrics path={path} />
      {/* <MetricsBackend path={path} /> */}
      <Reports
        key={`${orderBy}-${orderDirection}`}
        moduleID={moduleID}
        columns={serverColumns?.filter((field) => field.F_LIST === "Y")}
        title={title}
      />
      <EmailTrigger showEmailTriggerButton={showEmailTriggerButton} />
      <KPIsButton kpis={kpis} />
      {privileges.canInsert && (
        <Flex
          flexDirection={"row"}
          style={{
            flex: 3,
            justifyContent: "flex-end",
            marginRight: "1rem",
            color: "white",
            alignItems: "center",
          }}
        >
          <NewRegister
            title={title}
            columns={serverColumns}
            staticOptions={staticOptions}
            foreignKeyValues={foreignKeyValues}
            insertData={insertData}
            moduleID={moduleID}
            isProjectSpecific={isProjectSpecific}
            existingRowIDs={Object.keys(data) ?? []}
            privileges={privileges}
            updateTotals={updateTotals}
          />
          <Import moduleID={moduleID} />
        </Flex>
      )}
      <BannerSearch updateSearchValue={updateSearchValue} />
    </BannerWrapper>
  );
};

export default Banner;
