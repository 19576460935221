const createFormula = (string, fields) => {
  if (!string) {
    return null;
  }

  let newString = string.slice(2);

  //   if (newString.includes("abs")) {
  //     newString = newString.split("abs").join("#abs#");
  //   }

  //   if (newString.includes("sqrt")) {
  //     newString = newString.split("sqrt").join("#sqrt#");
  //   }

  //   if (newString.includes("log")) {
  //     newString = newString.split("log").join("#log#");
  //   }

  //   if (newString.includes("exp")) {
  //     newString = newString.split("exp").join("#exp#");
  //   }

  if (newString.includes("M_PI")) {
    newString = newString.split("M_PI").join("π");
  }

  //   if (newString.includes("pow")) {
  //     newString = newString.split("pow").join("#pow#");
  //   }

  const tempTags = newString.split(/[[#]/).filter((item) => item);
  let finalTempTags = [];

  tempTags.forEach((tag) => {
    if (tag.includes(",")) {
      const splitedTag = tag.split(",");
      splitedTag.forEach((splitTag, index) => {
        if (index !== splitedTag.length - 1) {
          finalTempTags.push(splitTag);
          finalTempTags.push(",");
        } else {
          finalTempTags.push(splitTag);
        }
      });
    } else {
      finalTempTags.push(tag);
    }
  });

  let newTags = [];

  finalTempTags.forEach((item) => {
    // if (item === "abs") {
    //   newTags.push("#abs#");
    // } else if (item === "log") {
    //   newTags.push("#log#");
    // } else if (item === "exp") {
    //   newTags.push("#exp#");
    // } else if (item === "pow") {
    //   newTags.push("#pow#");
    // } else if (item === "sqrt") {
    //   newTags.push("#sqrt#");
    // } else if (item === "M_PI") {
    //   newTags.push("#M_PI#");
    if (item.includes("]")) {
      const fieldID = item.split("]")[0];
      const fieldTitle = fields.filter((field) => field?.F_ID === fieldID)?.[0]
        ?.T_TITLE;
      newTags.push(fieldTitle);
      if (item.split("]")[1]) {
        newTags.push(item.split("]")[1]);
      }
    } else {
      newTags.push(item);
    }
  });

  let formula = "";
  newTags.map((item) => {
    if (formula !== "") {
      formula += " ";
    }
    formula += item;
  });

  return formula;
};

export default createFormula;
