const config = (set) => ({
  allData: {},
  setAllData: (newAllData) => set(() => ({ allData: newAllData })),

  paramMenu: [],
  setParamMenu: (newParamMenu) => set(() => ({ paramMenu: newParamMenu })),

  paramMenuTitle: "",
  setParamMenuTitle: (newParamMenuTitle) =>
    set(() => ({ paramMenuTitleTitle: newParamMenuTitle })),

  restData: {},
  setRestData: (newRestData) => set(() => ({ restData: newRestData })),

  orderBy: {},
  setOrderBy: (newOrderBy) => set(() => ({ orderBy: newOrderBy })),

  orderDirection: {},
  setOrderDirection: (newOrderDirection) =>
    set(() => ({ orderDirection: newOrderDirection })),

  buttonModalVisible: [],
  setButtonModalVisible: (newButtonModalVisible) =>
    set(() => ({ buttonModalVisible: newButtonModalVisible })),

  buttonData: {},
  setButtonData: (newButtonData) => set(() => ({ buttonData: newButtonData })),

  buttonAllData: {},
  setButtonAllData: (newButtonAllData) =>
    set(() => ({ buttonAllData: newButtonAllData })),

  buttonRestData: {},
  setButtonRestData: (newButtonRestData) =>
    set(() => ({ buttonRestData: newButtonRestData })),

  buttonColumnData: [],
  setButtonColumnData: (newButtonColumnData) =>
    set(() => ({ buttonColumnData: newButtonColumnData })),

  buttonFilters: {},
  setButtonFilters: (newButtonFilters) =>
    set(() => ({ buttonFilters: newButtonFilters })),

  showTotals: true,
  setShowTotals: (newShowTotals) => set(() => ({ showTotals: newShowTotals })),

  dateTypes: ["4", "5"],

  numberTypes: ["2", "3", "116"],

  textTypes: ["1", "27"],

  fromOtherModuleTypes: ["7", "28"],

  frozenColumns: {},
  setFrozenColumns: (newFrozenColumns) =>
    set(() => ({ frozenColumns: newFrozenColumns })),
});

const ModuleStore = {
  config,
  shouldLog: true,
};

export default ModuleStore;
