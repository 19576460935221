const countTableRowTotal = (row, headers) => {
  let total = 0;

  headers.forEach((header) => {
    total += Math.round(row?.[header] * 100) / 100;
  });

  return total;
};

export default countTableRowTotal;
