import { ResponsiveBar } from "@nivo/bar";
import Flex from "../../../../helpers/components/Flex";
import {
  useChartStore,
  useCommonTitlesStore,
} from "../../../../store/useStores";
import createLabels from "../../UC helpers/createLabels";
import "./bar.css";

const Bar = ({ data, indexBy, keys, total, max, columns, staticOptions }) => {
  const colorPosition = useChartStore((state) => state.colorPosition);
  const setColorPosition = useChartStore((state) => state.setColorPosition);
  const colorSet20 = useChartStore((state) => state.colorSet20);
  const colorSet40 = useChartStore((state) => state.colorSet40);
  const groupMode = useChartStore((state) => state.groupMode);
  const layout = useChartStore((state) => state.layout);
  const grouping = useChartStore((state) => state.grouping);
  const displayFunction = useChartStore((state) => state.displayFunction);
  const dateField = useChartStore((state) => state.dateField);
  const groupField = useChartStore((state) => state.groupField);
  const sumField = useChartStore((state) => state.sumField);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  let childDateField;
  let dateFieldToUse = dateField;

  if (dateField?.includes("-")) {
    let tempDateField = dateField.split(" - ");

    dateFieldToUse = tempDateField[0];
    childDateField = tempDateField[1];
  }

  const dateFieldColumn = columns?.filter(
    (column) => column.F_ID === dateFieldToUse
  )[0];

  let dateFieldName;
  let dateFieldType;
  let childDateFieldIndex;
  let childDateNumber;
  if (childDateField) {
    const childTitle = dateFieldColumn?.DEP_FIELDS?.filter(
      (field) => field.F_ID === childDateField
    ).map((item) => item.TITLE);
    const childType = dateFieldColumn?.DEP_FIELDS?.filter(
      (field) => field.F_ID === childDateField
    ).map((item) => item.F_TYPE);
    dateFieldName = `${dateFieldColumn?.T_TITLE} - ${childTitle}`;
    dateFieldType = childType;
    childDateNumber = dateFieldColumn?.DEP_FIELDS?.length;
    const dependentFields = columns?.filter(
      (column) => column.F_ID === dateFieldToUse
    )[0]?.DEP_FIELDS;

    childDateFieldIndex = dependentFields.findIndex((object) => {
      return object.F_ID === childDateField;
    });
  } else {
    dateFieldName = dateFieldColumn?.T_TITLE;
    dateFieldType = dateFieldColumn?.F_TYPE;
  }

  let childGroupField;
  let groupFieldToUse = groupField;

  if (groupField?.includes("-")) {
    let tempGroupField = groupField.split(" - ");

    groupFieldToUse = tempGroupField[0];
    childGroupField = tempGroupField[1];
  }

  const groupFieldColumn = columns?.filter(
    (column) => column.F_ID === groupFieldToUse
  )[0];

  let groupFieldName;
  let groupFieldType;
  let childGroupFieldIndex;
  let childGroupNumber;
  if (childGroupField) {
    const childTitle = groupFieldColumn?.DEP_FIELDS?.filter(
      (field) => field.F_ID === childGroupField
    ).map((item) => item.TITLE);
    const childType = groupFieldColumn?.DEP_FIELDS?.filter(
      (field) => field.F_ID === childGroupField
    ).map((item) => item.F_TYPE);
    groupFieldName = `${groupFieldColumn?.T_TITLE} - ${childTitle}`;
    groupFieldType = childType;
    childGroupNumber = groupFieldColumn?.DEP_FIELDS?.length;
    const dependentFields = columns?.filter(
      (column) => column.F_ID === groupFieldToUse
    )[0]?.DEP_FIELDS;

    childGroupFieldIndex = dependentFields.findIndex((object) => {
      return object.F_ID === childGroupField;
    });
  } else {
    groupFieldName = groupFieldColumn?.T_TITLE;
    groupFieldType = groupFieldColumn?.F_TYPE;
  }

  let dataForLegends = [];

  let dataForLegendsPart1 = [];
  let dataForLegendsPart2 = [];
  let numOfLegends = 0;

  //console.log("data: ", data);

  if (["sum", "pctSum"].includes(displayFunction) && !sumField) {
    dataForLegends = Object.values(data).map((item) => item?.id);
    if (dataForLegends.length <= 20) {
      dataForLegendsPart1 = dataForLegends;
    } else {
      dataForLegendsPart1 = dataForLegends.slice(
        0,
        dataForLegends.length / 2 - 1
      );
      dataForLegendsPart2 = dataForLegends.slice(
        dataForLegends.length / 2 - 1,
        dataForLegends.length
      );
    }
  } else {
    if (groupFieldType === "6") {
      let options = Object.values(staticOptions?.[groupField]);
      options.sort((a, b) => a.ORDER - b.ORDER);
      dataForLegends = options.map((option) => option.TITLE);
      numOfLegends = dataForLegends.length;
      if (dataForLegends.length > 20) {
        dataForLegendsPart1 = dataForLegends.slice(
          0,
          dataForLegends.length / 2 - 1
        );
        dataForLegendsPart2 = dataForLegends.slice(
          dataForLegends.length / 2 - 1,
          dataForLegends.length
        );
        dataForLegendsPart2.push(
          `${commonTitles["CO|1|NODATAFOR"]} ${groupFieldName}`
        );
      } else {
        dataForLegendsPart1 = dataForLegends;
        dataForLegendsPart1.push(
          `${commonTitles["CO|1|NODATAFOR"]} ${groupFieldName}`
        );
      }
    } else {
      let newItem = Object.fromEntries(
        Object.entries(data[0]).filter(([key]) => key !== "id" && key !== "sum")
      );
      dataForLegends.push(newItem);

      numOfLegends = Object.keys(dataForLegends[0]).length;

      if (numOfLegends <= 20) {
        dataForLegendsPart1.push(...Object.keys(dataForLegends[0]));
        // dataForLegendsPart1.push(
        //   `${commonTitles["CO|1|NODATAFOR"]} ${groupFieldName}`
        // );
      } else {
        for (let i = 0; i < Object.keys(dataForLegends[0]).length; i++) {
          if (i <= numOfLegends / 2 - 1) {
            dataForLegendsPart1.push(Object.keys(dataForLegends[0])[i]);
          } else {
            dataForLegendsPart2.push(Object.keys(dataForLegends[0])[i]);
          }
        }
        // dataForLegendsPart2.push(
        //   `${commonTitles["CO|1|NODATAFOR"]} ${groupFieldName}`
        // );
      }
    }
  }

  const numberOfItems = data.length;
  let padding = "0.1";
  if (parseInt(numberOfItems) < 2 && groupMode === "stacked") {
    padding = "0.8";
  }
  if (parseInt(numberOfItems) > 1 && groupMode === "stacked") {
    padding = "0.7";
  }

  // let newItem = Object.fromEntries(
  //   Object.entries(data[0]).filter(([key]) => key !== "id" && key !== "sum")
  // );
  // dataForLegends.push(newItem);

  // const numOfLegends = Object.keys(dataForLegends[0]).length;

  // if (numOfLegends <= 20) {
  //   dataForLegendsPart1.push(...Object.keys(dataForLegends[0]));
  // } else {
  //   for (let i = 0; i < Object.keys(dataForLegends[0]).length; i++) {
  //     if (i <= numOfLegends / 2 - 1) {
  //       dataForLegendsPart1.push(Object.keys(dataForLegends[0])[i]);
  //     } else {
  //       dataForLegendsPart2.push(Object.keys(dataForLegends[0])[i]);
  //     }
  //   }
  // }

  // console.log("data: ", data);
  // console.log("staticOptions: ", staticOptions[groupField]);

  // console.log("dataForLegends: ", dataForLegends);
  // console.log("groupFieldType: ", groupFieldType);
  // console.log("dataForLegendsPart1: ", dataForLegendsPart1);

  let chartColor = colorSet20;
  if (numOfLegends > 20) {
    chartColor = [...colorSet20, ...colorSet40];
  }

  //console.log({ dataForLegendsPart1 });

  // dataForLegendsPart1.sort();
  // dataForLegendsPart2.sort();

  const { XLabel, YLabel } = createLabels(
    grouping,
    displayFunction,
    commonTitles,
    dateField,
    columns
  );

  let colorKeys = keys;

  if (groupFieldType === "6") {
    colorKeys = Object.values(staticOptions?.[groupField]).map(
      (item) => item.TITLE
    );
  }

  if (
    !groupField ||
    (["sum", "pctSum"].includes(displayFunction) && !sumField)
  ) {
    colorKeys = data.map((item) => item.id);
  }

  //console.log("colorKeys: ", colorKeys);

  let i = 0;
  let barColors = {};

  colorKeys.forEach((key, index) => {
    if (groupFieldType === "6") {
      const color = Object.values(staticOptions?.[groupField]).filter(
        (item) => item?.TITLE === key
      )?.[0]?.BC;

      if (!["#ffffff", "rgb(255, 255, 255)"].includes(color) && color) {
        barColors = { ...barColors, [key]: color };
      } else {
        barColors = { ...barColors, [key]: chartColor[i] };
        i++;
      }
    } else if (!groupFieldType && dateFieldType === "6") {
      const color = Object.values(staticOptions?.[dateField]).filter(
        (item) => item?.TITLE === key
      )?.[0]?.BC;

      if (!["#ffffff", "rgb(255, 255, 255)"].includes(color) && color) {
        barColors = { ...barColors, [key]: color };
      } else {
        barColors = { ...barColors, [key]: chartColor[i] };
        i++;
      }
    } else {
      barColors = { ...barColors, [key]: chartColor[i] };
      i++;
    }
  });

  barColors = {
    ...barColors,
    [`${commonTitles["CO|1|NODATAFOR"]} ${groupFieldName}`]: "white",
  };

  //console.log("barColors: ", barColors);

  const getColor = (bar) => {
    if (
      !groupField ||
      (["sum", "pctSum"].includes(displayFunction) && !sumField)
    ) {
      return barColors?.[bar?.indexValue];
    }
    return barColors?.[bar?.id];
  };

  let showLegends = true;

  if (!groupField) {
    showLegends = false;
  }

  if (["sum", "pctSum"].includes(displayFunction) && !sumField) {
    showLegends = false;
  }

  const BarTotalsLayer = (props) => {
    let labelOffset = 10;
    const labelFontSize = 12;
    if (props.bars.length === 0) return null;
    // compute totals for each index/bar
    const totals = {};
    let bandwidth = layout === "vertical" ? props.bars[0].width : {};
    props.bars.forEach((bar) => {
      const indexValue = bar.data.indexValue;
      if (!(indexValue in totals)) {
        totals[indexValue] = 0;
      }
      if (!bar.data.hidden) {
        totals[indexValue] += bar.data.value;
      }

      if (layout === "horizontal") {
        if (!(indexValue in bandwidth)) {
          bandwidth[indexValue] = 12;
          if (["pctCount", "pctSum"].includes(displayFunction)) {
            bandwidth[indexValue] = 22;
          }
        }

        if (bar?.y < bandwidth[indexValue]) {
          bandwidth[indexValue] = bar?.y;
        }
      }
    });

    // place text elements above the bars
    let labels = Object.keys(totals).map((indexValue) => {
      let x = props.xScale(indexValue) + bandwidth / 2;
      let y = props.yScale(totals[indexValue]) - labelOffset;

      if (layout === "horizontal") {
        x = props.xScale(totals[indexValue]) + bandwidth[indexValue];
        y = props.yScale(indexValue) + labelOffset;
      }

      // totals[indexValue] = parseFloat(totals[indexValue].toFixed(4));

      // console.log("totals[indexValue]: ", totals[indexValue]);

      return (
        <text
          key={"total." + indexValue}
          x={x}
          y={y}
          textAnchor={"middle"}
          fontWeight={"bold"}
          fontSize={labelFontSize}
        >
          {["pctCount", "pctSum"].includes(displayFunction)
            ? `${(totals[indexValue] * 100).toFixed(2)}%`
            : totals[indexValue]}
        </text>
      );
    });

    labels = labels.filter(
      (label) => label?.props?.children && label?.props?.children !== "0.00%"
    );
    return <>{labels}</>;
  };

  return (
    <>
      <ResponsiveBar
        key={`${max}-${sumField}-${groupMode}`}
        class
        data={data}
        keys={keys}
        indexBy={indexBy}
        tooltip={({ id, value }) => (
          <div
            style={{
              padding: 5,
              background: "rgb(230, 255, 255)",
            }}
          >
            <strong>
              {id}:{` ${Math.round(value * 100) / 100}`}
            </strong>
          </div>
        )}
        defs={[
          {
            id: "lines",
            type: "patternLines",
            background: "inherit",
            color: "rgb(161, 160, 160)",
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        fill={[
          {
            match: {
              id: `${commonTitles["CO|1|NODATAFOR"]} ${groupFieldName}`,
            },
            id: "lines",
          },
        ]}
        margin={{
          top: 50,
          // ["pctCount", "pctSum"].includes(displayFunction) &&
          // layout === "vertical"
          //   ? 110
          //   : 50,
          right:
            ["pctCount", "pctSum"].includes(displayFunction) &&
            layout === "horizontal"
              ? 600
              : 300,
          bottom: layout === "horizontal" ? 120 : 190,
          left: 180,
        }}
        padding={padding}
        innerPadding={groupMode === "stacked" ? 0 : 4}
        layout={layout}
        groupMode={groupMode}
        valueFormat={
          ["pctCount", "pctSum"].includes(displayFunction) && " >-.1%"
        }
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        // colors={keys.length > 10 ? chartColorPalette : { scheme: color }}
        colors={getColor}
        enableGridX={layout === "horizontal" && true}
        enableGridY={layout === "vertical" && true}
        gridYValues={layout === "vertical" && total}
        gridXValues={layout === "horizontal" && total}
        layers={[
          "grid",
          "axes",
          "markers",
          "bars",
          "legends",
          "annotations",
          groupMode === "stacked" && BarTotalsLayer,
        ]}
        theme={{
          axis: {
            legend: {
              text: {
                fill: "rgb(8, 8, 46)",
              },
            },
            ticks: {
              text: {
                fill: "rgb(8, 8, 46)",
              },
            },
          },
        }}
        // borderColor={{
        //   from: "color",
        //   modifiers: [["darker", 1.6]],
        // }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickValues: layout === "horizontal" && total,
          tickPadding: 10,
          tickRotation: layout === "horizontal" ? 0 : -90,
          legend: layout === "vertical" ? XLabel : YLabel,
          legendPosition: "middle",
          legendOffset: layout === "horizontal" ? 50 : 100,
        }}
        axisLeft={{
          tickSize: 5,
          tickValues: layout === "vertical" && total,
          tickPadding: 5,
          tickRotation: 0,
          legend: layout === "vertical" ? YLabel : XLabel,
          legendPosition: "middle",
          legendOffset: layout === "vertical" ? -40 : -80,
          LegendTextFill: "#rgb(8, 8, 46)",
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        // labelTextColor={{
        //   from: "color",
        //   modifiers: [["darker", 3.6]],
        // }}
        // legends={[
        //   {
        //     dataFrom: "keys",
        //     anchor: "bottom-right",
        //     direction: "column",
        //     justify: false,
        //     translateX: 150,
        //     translateY: 0,
        //     itemsSpacing: 5,
        //     itemWidth: 50,
        //     itemHeight: 10,
        //     itemDirection: "left-to-right",
        //     symbolShape: "circle",
        //     itemOpacity: 0.85,
        //     itemTextColor: "#rgb(8, 8, 46)",
        //     symbolSize: 10,
        //     effects: [
        //       {
        //         on: "hover",
        //         style: {
        //           itemOpacity: 1,
        //         },
        //       },
        //     ],
        //   },
        // ]}
        role="application"
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={function (e) {
          return (
            e.id + ": " + e.formattedValue + ` in ${indexBy}: ` + e.indexValue
          );
        }}
      />
      <Flex
        key={sumField}
        flexDirection={"column"}
        style={{
          marginLeft: "-17rem",
          marginTop: "4rem",
          display: !showLegends && "none",
        }}
      >
        {dataForLegendsPart1.map((item, index) => (
          <Flex
            key={`${index}`}
            flexDirection={"row"}
            style={{ maxHeight: 13, marginTop: numOfLegends < 15 ? 4 : 2 }}
          >
            {item === `${commonTitles["CO|1|NODATAFOR"]} ${groupFieldName}` ? (
              <div
                className={
                  item ===
                    `${commonTitles["CO|1|NODATAFOR"]} ${groupFieldName}` &&
                  "noData"
                }
                style={{
                  width: numOfLegends < 15 ? 13 : 10,
                  maxHeight: 9,
                  borderRadius: 15,
                  border: "solid rgb(161, 160, 160) 0.5px",
                }}
              />
            ) : (
              <div
                style={{
                  width: numOfLegends < 15 ? 13 : 10,
                  maxHeight: 9,
                  borderRadius: 15,
                  background: barColors[item],
                }}
              />
            )}
            <p
              style={{
                marginTop: numOfLegends < 15 ? -3 : -2,
                marginLeft: 6,
                fontSize: numOfLegends < 15 ? 11 : 9,
                width: "max-content",
              }}
            >
              {item}
            </p>
          </Flex>
        ))}
      </Flex>
      <Flex
        flexDirection={"column"}
        style={{
          marginLeft: "0.7rem",
          marginTop: "4rem",
          display:
            (!groupField ||
              (["sum", "pctSum"].includes(displayFunction) && !sumField)) &&
            "none",
        }}
      >
        {dataForLegendsPart2.map((item, index) => (
          <Flex
            key={index}
            flexDirection={"row"}
            style={{ maxHeight: 13, marginTop: numOfLegends < 15 ? 4 : 2 }}
          >
            {item === `${commonTitles["CO|1|NODATAFOR"]} ${groupFieldName}` ? (
              <div
                className={
                  item ===
                    `${commonTitles["CO|1|NODATAFOR"]} ${groupFieldName}` &&
                  "noData"
                }
                style={{
                  width: numOfLegends < 15 ? 13 : 10,
                  maxHeight: 9,
                  borderRadius: 15,
                  border: "solid rgb(161, 160, 160) 0.5px",
                }}
              />
            ) : (
              <div
                style={{
                  width: numOfLegends < 15 ? 13 : 10,
                  maxHeight: 9,
                  borderRadius: 15,
                  background: barColors[item],
                }}
              />
            )}
            <p
              style={{
                marginTop: numOfLegends < 15 ? -3 : -2,
                marginLeft: 6,
                fontSize: numOfLegends < 15 ? 11 : 9,
                width: "max-content",
              }}
            >
              {item}
            </p>
          </Flex>
        ))}
      </Flex>
    </>
  );
};

export default Bar;
