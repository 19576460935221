import { Button } from "antd";
import { useState } from "react";
import "./css/ultimateTable.css";
import { Switch, Badge } from "antd";
import {
  HddOutlined,
  FilterOutlined,
  MenuUnfoldOutlined,
  CheckCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  VerticalLeftOutlined,
  PushpinOutlined,
} from "@ant-design/icons";
import { Dropdown, Layout, Tooltip } from "antd";
//import { DeleteModal, FiltersModal } from "./helpers/modals";
import DeleteModal from "./helpers/modals/deleteModal/DeleteModal";
import FiltersModal from "./helpers/modals/filtersModal/FiltersModal";
import { useAuthStore, useCommonTitlesStore } from "../../store/useStores";
import Flex from "../../helpers/components/Flex";
import useWindowDimensions from "./hooks/useWindowDimensions";
import NewRegister from "../module/banner/newRegister/NewRegister";

const UltimateTableSettings = ({
  columns,
  staticOptions,
  foreignKeyValues,
  config,
  grouping,
  showGroup,
  dense,
  changeDense,
  selectedItems,
  selection,
  changeSelection,
  deleteRow,
  changeDisplayedColumns,
  changeGrouping,
  forceSelection,
  filters,
  updateFilters,
  dataToUse,
  privileges,
  moduleID,
  insertData,
  existingRowIDs,
  isProjectSpecific,
  autoCompletedFields,
  title,
  frozenColumns = [],
  updateFrozenColumns,
  kpis = [],
}) => {
  const { Content } = Layout;
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const role = useAuthStore((state) => state.role);
  const { width } = useWindowDimensions();

  let selectionMsg2 = commonTitles["CO|1|SELECTMSG2"];
  var re = new RegExp("<1>", "g");
  selectionMsg2 = selectionMsg2.replace(re, selectedItems.length);

  let viewRights = {};
  columns.forEach((column) => {
    if (role === "A") {
      viewRights = { ...viewRights, [column.F_ID]: true };
    } else if (Object.keys(column).includes("canview")) {
      viewRights = {
        ...viewRights,
        [column.F_ID]: column.canview,
      };
    } else {
      viewRights = {
        ...viewRights,
        [column.F_ID]: privileges?.canView,
      };
    }
  });

  let columnsMenuItems = columns
    .filter((column) => viewRights[column.F_ID])
    .map((item) => ({
      label: (
        <div onClick={() => changeDisplayedColumns(item.F_ID, viewRights)}>
          <Flex flexDirection={"row"}>
            {item.F_LIST === "Y" ? (
              <div className="check-icon">
                <CheckCircleOutlined style={{ color: "#389e0d" }} width={5} />
              </div>
            ) : (
              <div className="ghost-icon"></div>
            )}
            {item.T_TITLE}
          </Flex>
        </div>
      ),
      key: item.F_ID,
    }));

  let existUnshownColumn = false;
  columns.forEach((item) => {
    if (item.F_LIST !== "Y") {
      existUnshownColumn = true;
    }
  });

  if (existUnshownColumn) {
    columnsMenuItems.push({
      label: (
        <div onClick={() => changeDisplayedColumns("", viewRights)}>
          <Flex flexDirection={"row"}>
            <div className="check-icon">
              <VerticalLeftOutlined
                style={{ color: "rgb(150, 184, 238)" }}
                width={5}
              />
            </div>
            ({commonTitles["CO|1|SELECTALL"]})
          </Flex>
        </div>
      ),
      key: "",
    });
  }

  let groupingMenuItems = [];

  if (grouping.length) {
    groupingMenuItems.push({
      label: (
        <div onClick={() => changeGrouping("")}>
          <Flex flexDirection={"row"}>
            <div className="check-icon">
              <CloseOutlined
                style={{ color: "rgb(150, 184, 238)" }}
                width={5}
              />
            </div>
            ({commonTitles["CO|1|CLEARGRP"]})
          </Flex>
        </div>
      ),
      key: "",
    });
  }

  const groupingColumns = columns.filter(
    (item) =>
      item.F_TYPE !== "1" /* type = text */ &&
      item.F_TYPE !== "27" /* type = textarea */ &&
      item.F_TYPE !== "24" /* type = document link */ &&
      item.F_TYPE !== "25" /* type = single image link */ &&
      item.F_TYPE !== "29" /* type = aytomatic code */ &&
      item.F_LIST === "Y" &&
      viewRights[item.F_ID]
  );

  groupingColumns.forEach((item) => {
    if (item.F_TYPE === "4" /* type = date */) {
      let fullDateOption = {
        label: (
          <div onClick={() => changeGrouping(item.F_ID)}>
            <Flex flexDirection={"row"}>
              {showGroup === item.F_ID ? (
                <div className="check-icon">
                  <CheckCircleOutlined style={{ color: "#389e0d" }} width={5} />
                </div>
              ) : (
                <div className="ghost-icon"></div>
              )}
              {item.T_TITLE} - {commonTitles["CO|1|FULLDATE"]}
            </Flex>
          </div>
        ),
        key: item.F_ID,
      };

      let monthOption = {
        label: (
          <div onClick={() => changeGrouping(`${item.F_ID} - month`)}>
            <Flex flexDirection={"row"}>
              {showGroup === `${item.F_ID} - month` ? (
                <div className="check-icon">
                  <CheckCircleOutlined style={{ color: "#389e0d" }} width={5} />
                </div>
              ) : (
                <div className="ghost-icon"></div>
              )}
              {item.T_TITLE} - {commonTitles.month}
            </Flex>
          </div>
        ),
        key: `${item.F_ID} - month`,
      };
      groupingMenuItems.push(fullDateOption, monthOption);
    } else {
      let option = {
        label: (
          <div onClick={() => changeGrouping(item.F_ID)}>
            <Flex flexDirection={"row"}>
              {showGroup === item.F_ID ? (
                <div className="check-icon">
                  <CheckCircleOutlined style={{ color: "#389e0d" }} width={5} />
                </div>
              ) : (
                <div className="ghost-icon"></div>
              )}
              {item.T_TITLE}
            </Flex>
          </div>
        ),
        key: item.F_ID,
      };

      groupingMenuItems.push(option);
    }
  });

  const [filtersModalVisible, setFiltersModalVisible] = useState(false);

  const showFiltersModal = () => {
    setFiltersModalVisible(true);
  };

  const applyFilters = (changes, config) => {
    updateFilters(changes, config);
    setFiltersModalVisible(false);
  };

  const cancelFiltersModal = () => {
    setFiltersModalVisible(false);
  };

  const eraseFilters = () => {
    updateFilters(null, "erase");
    setFiltersModalVisible(false);
  };

  const filterCount = filters ? Object.keys(filters).length : 0;

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const cancelDeleteModal = () => {
    setDeleteModalVisible(false);
  };

  const onDelete = (moduleID) => {
    setDeleteModalVisible(false);
    deleteRow(selectedItems, "multiple", config, moduleID);
  };

  let pinnedMenuItems = columns
    .filter((column) => viewRights[column.F_ID] && column?.F_LIST === "Y")
    .map((item) => ({
      label: (
        <div onClick={() => updateFrozenColumns(item.F_ID)}>
          <Flex flexDirection={"row"}>
            {frozenColumns.includes(item?.F_ID) ? (
              <div className="check-icon">
                <PushpinOutlined style={{ color: "#389e0d" }} width={5} />
              </div>
            ) : (
              <div className="ghost-icon"></div>
            )}
            {item.T_TITLE}
          </Flex>
        </div>
      ),
      key: item.F_ID,
    }));

  const [kpisModalVisible, setKpisModalVisible] = useState(false);

  const showKpisModal = () => {
    setKpisModalVisible(true);
  };

  const cancelKpisModal = () => {
    setKpisModalVisible(false);
  };

  return (
    <Flex flexDirection={"row"}>
      <Content
        style={{
          padding: 10,
          minHeight: "max-content",
          backgroundColor: "transparent",
        }}
      >
        <Flex flexDirection={"row"}>
          {config === "info" && (
            <NewRegister
              title={title}
              columns={columns}
              staticOptions={staticOptions}
              foreignKeyValues={foreignKeyValues}
              insertData={insertData}
              moduleID={moduleID}
              isProjectSpecific={isProjectSpecific}
              existingRowIDs={existingRowIDs}
              privileges={privileges}
              config={"info"}
              autoCompletedFields={autoCompletedFields}
            />
          )}
          {config !== "info" && (
            <Dropdown
              menu={{ items: columnsMenuItems }}
              overlayStyle={{
                overflowY: "auto",
                maxHeight: "70vh",
                borderRadius: 10,
              }}
              className="dropdown"
            >
              <Button
                shape="round"
                icon={<HddOutlined rotate={90} color="rgb(8, 8, 46)" />}
              >
                {commonTitles["CO|17|BTNFIELDS"]}
              </Button>
            </Dropdown>
          )}

          {config === "module" && (
            <>
              <Button
                shape="round"
                icon={<FilterOutlined color="rgb(8, 8, 46)" />}
                onClick={showFiltersModal}
                className="dropdown"
              >
                {commonTitles["CO|1|FILTERS"]}
                {filters && filterCount > 0 && (
                  <Badge
                    style={{
                      marginLeft: 8,
                      marginBottom: 4,
                      backgroundColor: "rgb(8, 8, 46)",
                    }}
                    count={filterCount}
                  />
                )}
              </Button>
              {filtersModalVisible && (
                <FiltersModal
                  visible={filtersModalVisible}
                  columns={columns}
                  staticOptions={staticOptions}
                  foreignKeyValues={foreignKeyValues}
                  onSave={applyFilters}
                  onCancel={cancelFiltersModal}
                  onErase={eraseFilters}
                  filters={filters}
                  privileges={privileges}
                  moduleID={moduleID}
                />
              )}
            </>
          )}

          {config !== "info" && (
            <Dropdown
              menu={{ items: groupingMenuItems }}
              disabled={dataToUse.length === 0 ? true : false}
              overlayStyle={{
                overflowY: "auto",
                maxHeight: "70vh",
                borderRadius: 10,
              }}
              className="dropdown"
            >
              <Button
                shape="round"
                icon={<MenuUnfoldOutlined color="rgb(8, 8, 46)" />}
              >
                {commonTitles["CO|1|GRPBY"]}
              </Button>
            </Dropdown>
          )}

          {config === "module" && (
            <Dropdown
              menu={{ items: pinnedMenuItems }}
              disabled={dataToUse.length === 0 ? true : false}
              overlayStyle={{
                overflowY: "auto",
                maxHeight: "70vh",
                borderRadius: 10,
              }}
              className="dropdown"
            >
              <Button
                shape="round"
                icon={<PushpinOutlined color="rgb(8, 8, 46)" />}
              >
                {commonTitles["CO|1|PINCOL"]}
              </Button>
            </Dropdown>
          )}

          {Object.keys(filters).length ? (
            <Button key="erase" type="primary" onClick={eraseFilters}>
              {commonTitles["CO|1|CLEARFIL"]}
            </Button>
          ) : null}
        </Flex>
      </Content>

      {privileges?.canDelete && (
        <div
          className="switch-button"
          style={{ display: width < 700 ? "none" : "flex" }}
        >
          {selection && selectedItems.length ? (
            <>
              <Tooltip
                placement="bottom"
                title={commonTitles["CO|1|DELETEBTN"]}
              >
                <Button
                  className="delete-icon"
                  style={{ backgroundColor: "rgb(180, 203, 241)" }}
                  shape="round"
                  icon={<DeleteOutlined color="rgb(8, 8, 46)" />}
                  onClick={() => setDeleteModalVisible(true)}
                />
              </Tooltip>

              {deleteModalVisible && (
                <DeleteModal
                  visible={deleteModalVisible}
                  selectedItems={selectedItems}
                  onDelete={onDelete}
                  onCancel={cancelDeleteModal}
                  moduleID={moduleID}
                  closable
                />
              )}

              <div className="selected-info">
                {selectedItems.length === 1 ? (
                  <p>{commonTitles["CO|1|SELECTMSG1"]}</p>
                ) : (
                  <p>{selectionMsg2}</p>
                )}
              </div>
            </>
          ) : null}

          <Tooltip placement="bottom" title={commonTitles["CO|1|SELECTIONM"]}>
            <div className="switch">
              <p>{commonTitles["CO|1|SELECTION"]}</p>
              <Switch
                checked={selection}
                onChange={() => {
                  if (selection) {
                    forceSelection([]);
                  }
                  changeSelection();
                }}
              />
            </div>
          </Tooltip>
        </div>
      )}
      <div
        className="switch-button"
        style={{ display: width < 1000 ? "none" : "flex" }}
      >
        <Tooltip placement="left" title={commonTitles["CO|1|DENSEMSG"]}>
          <div className="switch">
            <p>{commonTitles["CO|1|DENSE"]}</p>
            <Switch checked={dense} onChange={changeDense} />
          </div>
        </Tooltip>
      </div>
    </Flex>
  );
};

export default UltimateTableSettings;
