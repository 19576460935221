import moment from "moment";
import { convertMonthToLabel } from "../../../ultimateTable/helpers/converters";

const withGroupDateMonthly = (
  columns,
  data,
  xAxisField,
  dateFormatToUse,
  commonTitles,
  groupField,
  foreignKeyValues,
  staticListColumns,
  dependentColumns,
  countColumns,
  numberAlertColumns,
  multipleValuesColumns,
  language,
  xValuesUsed,
  yValuesUsed,
  noDateDataColumnStatus
) => {
  let childxAxisField;
  let xAxisFieldToUse = xAxisField;

  if (xAxisField?.includes("-")) {
    let tempXAxisField = xAxisField.split(" - ");

    xAxisFieldToUse = tempXAxisField[0];
    childxAxisField = tempXAxisField[1];
  }

  const xAxisFieldColumn = columns.filter(
    (column) => column.F_ID === xAxisFieldToUse
  )[0];

  let xAxisFieldName;
  let childxAxisFieldIndex;
  let childxAxisNumber;

  if (childxAxisField) {
    const childTitle = xAxisFieldColumn.DEP_FIELDS?.filter(
      (field) => field.F_ID === childxAxisField
    ).map((item) => item.TITLE);
    xAxisFieldName = `${xAxisFieldColumn.T_TITLE} - ${childTitle}`;
    childxAxisNumber = xAxisFieldColumn.DEP_FIELDS?.length;
    const dependentFields = columns?.filter(
      (column) => column.F_ID === xAxisFieldToUse
    )[0].DEP_FIELDS;

    childxAxisFieldIndex = dependentFields.findIndex((object) => {
      return object.F_ID === childxAxisField;
    });
  } else {
    xAxisFieldName = xAxisFieldColumn.T_TITLE;
  }

  const isAlert =
    columns.filter((column) => column?.F_ID === xAxisFieldToUse)?.[0]
      ?.F_RENDER === "ALERT"
      ? true
      : false;

  const isDependent =
    columns.filter((column) => column?.F_ID === xAxisFieldToUse)?.[0]
      ?.F_TYPE === "30"
      ? true
      : false;

  let months = data.map((item) => {
    if (dependentColumns.includes(xAxisFieldToUse)) {
      if (item[xAxisFieldToUse] && item[xAxisFieldToUse].includes(",")) {
        const tempValue = item[xAxisFieldToUse].split(", ");
        const finalValue = tempValue[childxAxisFieldIndex];
        let month = moment(finalValue, dateFormatToUse).toDate().getMonth();
        let year = moment(finalValue, dateFormatToUse).toDate().getFullYear();
        return `${month} ${year}`;
      } else {
        return item[xAxisFieldToUse];
      }
    } else {
      if (isAlert) {
        if (item[xAxisFieldToUse]?.value) {
          let month = moment(item[xAxisFieldToUse]?.value, dateFormatToUse)
            .toDate()
            .getMonth();
          let year = moment(item[xAxisFieldToUse]?.value, dateFormatToUse)
            .toDate()
            .getFullYear();
          return `${month} ${year}`;
        } else return null;
      } else {
        if (item[xAxisFieldToUse]) {
          let month = moment(item[xAxisFieldToUse], dateFormatToUse)
            .toDate()
            .getMonth();
          let year = moment(item[xAxisFieldToUse], dateFormatToUse)
            .toDate()
            .getFullYear();
          return `${month} ${year}`;
        } else return null;
      }
    }
  });

  let uniqueMonths = [];
  months.forEach((item) => {
    if (item && !uniqueMonths.includes(item)) {
      uniqueMonths.push(item);
    }
  });

  let results = [];

  uniqueMonths.forEach((item) => {
    let month = item.substring(0, item.indexOf(" "));
    let year = item.substring(item.indexOf(" ") + 1);
    let label =
      convertMonthToLabel(parseInt(month), commonTitles, "short", language) +
      " " +
      year;
    results.push({ month: month, year: year, label: label });
  });

  //console.log("results: ", results);

  uniqueMonths = results;

  //console.log("uniqueMonths: ", uniqueMonths);

  uniqueMonths.sort((a, b) => {
    if (a.year !== b.year) {
      return a.year - b.year;
    } else {
      return a.month - b.month;
    }
  });

  if (!noDateDataColumnStatus) {
    uniqueMonths.push({ label: commonTitles["CO|1|NODATEDATA"] });
  }

  let childGroupField;
  let groupFieldToUse = groupField;

  if (groupField?.includes("-")) {
    let tempGroupField = groupField.split(" - ");

    groupFieldToUse = tempGroupField[0];
    childGroupField = tempGroupField[1];
  }

  const groupFieldColumn = columns.filter(
    (column) => column.F_ID === groupFieldToUse
  )[0];

  let groupFieldName;
  let childGroupFieldIndex;
  let childGroupNumber;
  if (childGroupField) {
    const childTitle = groupFieldColumn?.DEP_FIELDS.filter(
      (field) => field.F_ID === childGroupField
    ).map((item) => item.TITLE);
    groupFieldName = `${groupFieldColumn?.T_TITLE} - ${childTitle}`;
    childGroupNumber = groupFieldColumn?.DEP_FIELDS.length;
    const dependentFields = columns.filter(
      (column) => column.F_ID === groupFieldToUse
    )[0]?.DEP_FIELDS;

    childGroupFieldIndex = dependentFields.findIndex((object) => {
      return object.F_ID === childGroupField;
    });
  } else {
    groupFieldName = groupFieldColumn?.T_TITLE;
  }

  let groupFieldValues = data.map((item) => {
    if (multipleValuesColumns.includes(groupFieldToUse)) {
      return item[`${groupFieldToUse}-server`];
    } else if (dependentColumns.includes(groupFieldToUse)) {
      if (item[groupFieldToUse] && item[groupFieldToUse].includes(",")) {
        const tempValue = item[groupFieldToUse].split(", ");
        const finalValue = tempValue[childGroupFieldIndex];
        return finalValue;
      } else {
        return item[groupFieldToUse];
      }
    } else if (numberAlertColumns.includes(groupFieldToUse)) {
      return item[groupFieldToUse]?.value;
    } else return item[groupFieldToUse];
  });

  //console.log("groupFieldValues: ", groupFieldValues);

  let uniqueGroupFieldValues = [];
  groupFieldValues.forEach((item) => {
    if (
      item &&
      staticListColumns.includes(groupFieldToUse) &&
      typeof item === "object"
    ) {
      item = item?.view;
    }
    if (
      item &&
      item.includes(",") &&
      !countColumns.includes(groupFieldToUse) &&
      !staticListColumns.includes(groupFieldToUse)
    ) {
      const myArray = item.split(",");
      myArray.forEach((value) => {
        if (value && !uniqueGroupFieldValues.includes(value)) {
          uniqueGroupFieldValues.push(value);
        }
      });
    } else {
      if (item && !uniqueGroupFieldValues.includes(item)) {
        uniqueGroupFieldValues.push(item);
      }
    }
  });

  //console.log("uniqueGroupFieldValues: ", uniqueGroupFieldValues);

  if (multipleValuesColumns.includes(groupFieldToUse)) {
    uniqueGroupFieldValues = uniqueGroupFieldValues.map((value) => {
      if (foreignKeyValues[groupFieldToUse][value]?.view.includes(",")) {
        const myArray =
          foreignKeyValues[groupFieldToUse][value]?.view.split(", ");
        return myArray[0];
      } else {
        return foreignKeyValues[groupFieldToUse][value]?.view;
      }
    });
  }

  if (!yValuesUsed) {
    uniqueGroupFieldValues.push(
      `${commonTitles["CO|1|NODATAFOR"]} ${groupFieldName}`
    );
  }

  const isGroupFieldAlert =
    columns.filter((column) => column?.F_ID === groupFieldToUse)?.[0]
      ?.F_RENDER === "ALERT"
      ? true
      : false;

  let monthlyCount = {};

  for (let i = 0; i < uniqueMonths.length; i++) {
    for (let j = 0; j < uniqueGroupFieldValues.length; j++) {
      let counter = 0;
      let pseudoCounter = 0;
      //let noGroupFieldDataForMonth = true;
      for (let z = 0; z < data.length; z++) {
        let dateValue = isAlert
          ? data[z][xAxisFieldToUse]?.value
          : data[z][xAxisFieldToUse];

        let label;

        if (dateValue) {
          let month = moment(dateValue, dateFormatToUse).toDate().getMonth();
          let year = moment(dateValue, dateFormatToUse).toDate().getFullYear();
          label =
            convertMonthToLabel(month, commonTitles, "short", language) +
            " " +
            year;
        } else {
          label = commonTitles["CO|1|NODATEDATA"];
        }

        if (
          label === uniqueMonths[i].label ||
          (isDependent && label.includes(uniqueMonths[i].label))
        ) {
          let groupFieldValue = isGroupFieldAlert
            ? data[z][groupFieldToUse]?.value
            : data[z][groupFieldToUse];

          if (!groupFieldValue) {
            pseudoCounter += 1;
          }
          if (
            groupFieldValue &&
            staticListColumns.includes(groupFieldToUse) &&
            typeof groupFieldValue === "object"
          ) {
            groupFieldValue = groupFieldValue.view;
          }
          if (groupFieldValue && dependentColumns.includes(groupFieldToUse)) {
            if (groupFieldValue.includes(",")) {
              let tempArray = groupFieldValue.split(", ");
              if (
                tempArray[childGroupFieldIndex] === uniqueGroupFieldValues[j]
              ) {
                //noGroupFieldDataForMonth = false;
                counter += 1;
              }
            } else {
              if (groupFieldValue === uniqueGroupFieldValues[j]) {
                //noGroupFieldDataForMonth = false;
                counter += 1;
              }
            }
          } else {
            if (groupFieldValue) {
              if (
                groupFieldValue === uniqueGroupFieldValues[j] ||
                groupFieldValue.includes(uniqueGroupFieldValues[j])
              ) {
                //noGroupFieldDataForMonth = false;
                counter += 1;
              }
            }
          }
        }

        monthlyCount = {
          ...monthlyCount,
          [uniqueMonths[i].label]: {
            ...monthlyCount[uniqueMonths[i].label],
            [uniqueGroupFieldValues[j]]:
              uniqueGroupFieldValues[j] ===
              `${commonTitles["CO|1|NODATAFOR"]} ${groupFieldName}`
                ? pseudoCounter
                : counter,
          },
        };
      }
    }
  }

  let totalSum = 0;

  Object.entries(monthlyCount).forEach(([key, value]) => {
    let sum = 0;

    Object.values(value).forEach((item) => {
      sum += item;
      totalSum += item;
    });
    if (sum || !xValuesUsed) {
      monthlyCount[key] = {
        ...monthlyCount[key],
        sum: sum,
      };
    } else {
      if (xValuesUsed) {
        delete monthlyCount[key];
      }
    }
  });

  return {
    dailyCount: {},
    monthlyCount: { ...monthlyCount, totalSum: totalSum },
    yearlyCount: {},
  };
};

export default withGroupDateMonthly;
