import Flex from "../../../../../../helpers/components/Flex";
import { Select } from "antd";
import { useCommonTitlesStore } from "../../../../../../store/useStores";

const { Option } = Select;

const DependentMultipleSelection = ({
  value,
  id,
  fieldID,
  foreignKeyValues,
  addChange,
  title,
  config,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  let options = [];
  if (foreignKeyValues[fieldID]) {
    options = Object.entries(foreignKeyValues[fieldID]).map(([key, value]) => ({
      id: key,
      view: value.view,
    }));
    options.sort((a, b) => a.view.localeCompare(b.view));
  }
  return (
    <Flex flexDirection={"row"}>
      <div className="select">
        <Select
          mode="multiple"
          allowClear
          style={{
            width: 278,
            marginLeft: 7,
            marginTop: 4,
          }}
          showSearch={true}
          filterOption={(iv, op) =>
            op.value
              .toLocaleLowerCase()
              .includes((iv || "").toLocaleLowerCase())
          }
          onChange={(e) => addChange(id, { [fieldID]: e }, null)}
          placeholder={title}
          defaultValue={value ? value[fieldID] : []}
          dropdownStyle={{ overflowY: "auto" }}
          virtual={false}
        >
          <Option value={[]} />
          {config === "filters" && (
            <Option value={"NV"} className="option">
              {commonTitles?.["CO|1|NOVALUE"]}
            </Option>
          )}

          {options.map((item) => (
            <Option key={item?.view} className="option" value={item?.id}>
              {item?.view}
            </Option>
          ))}
        </Select>
      </div>
    </Flex>
  );
};

export default DependentMultipleSelection;
