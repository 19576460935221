const countTableColumnTotal = (bodyData, id) => {
  let total = 0;

  bodyData.forEach((item) => {
    total += Math.round(item?.[id] * 100) / 100;
  });

  return total;
};

export default countTableColumnTotal;
