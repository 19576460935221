import Flex from "../../../../../../../../helpers/components/Flex";
import { Button } from "antd";

const AddMathSymbol = ({ tags, updateTags }) => {
  const symbols = ["+", "-", "*", "/", "(", ")", "."];

  const handleChange = (symbol) => {
    if (tags.length) {
      if (
        !tags[tags.length - 1].includes("[") &&
        !tags[tags.length - 1].includes("#")
      ) {
        let newTags = [...tags];

        if (newTags[tags.length - 1] !== ",") {
          newTags[tags.length - 1] = tags[tags.length - 1] + symbol;
        } else {
          newTags.push(symbol);
        }

        updateTags(newTags);
      } else {
        updateTags([...tags, symbol]);
      }
    } else {
      updateTags([symbol]);
    }
  };

  return (
    <Flex flexDirection={"row"} style={{ gap: 8 }}>
      {symbols.map((symbol, index) => (
        <Button
          key={`${symbol}-${index}`}
          style={{ width: 15, display: "flex", justifyContent: "center" }}
          onClick={() => handleChange(symbol)}
        >
          {symbol}
        </Button>
      ))}
    </Flex>
  );
};

export default AddMathSymbol;
