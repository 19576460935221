import Flex from "../../../../../../../../helpers/components/Flex";
import AddMathOperationButton from "./AddMathOperationButton";
import AddFieldButton from "./AddFieldButton";
import AddFunctionButton from "./AddFunctionButton";
import EraseButton from "./EraseButton";
import AddFunctionParameterSeperator from "./AddFunctionParameterSeperator";
import { Tag } from "antd";
import AddNumber from "./AddNumber";
import AddMathSymbol from "./AddMathSymbol";
import AddFunction from "./AddFunction";
import AddField from "./AddField";

const Buttons = ({
  tags,
  updateTags,
  showInput,
  editFieldTagInfo,
  updateEditFieldTagInfo,
  editFunctionTagInfo,
  fieldTags,
  updateFieldTags,
  selectFieldModalVisible,
  showFieldModal,
  cancelFieldModal,
  functionModalVisible,
  showFunctionModal,
  cancelFunctionModal,
  addChange,
  currentFieldID,
  currentModuleID,
}) => {
  return (
    <Flex flexDirection={"column"} style={{ gap: 6 }}>
      {/* <Flex
        flexDirection={"row"}
        style={{
          alignItems: "center",
          marginTop: tags.length === 0 ? -15 : -5,
        }}
      >
        <AddMathOperationButton showInput={showInput} />
        <AddFieldButton
          editFieldTagInfo={editFieldTagInfo}
          updateEditFieldTagInfo={updateEditFieldTagInfo}
          updateTags={updateTags}
          updateFieldTags={updateFieldTags}
          fieldTags={fieldTags}
          tags={tags}
          selectFieldModalVisible={selectFieldModalVisible}
          showFieldModal={showFieldModal}
          cancelFieldModal={cancelFieldModal}
          currentFieldID={currentFieldID}
          currentModuleID={currentModuleID}
        />
      </Flex> */}
      <AddNumber tags={tags} updateTags={updateTags} />
      <AddMathSymbol tags={tags} updateTags={updateTags} />
      <AddFunction
        tags={tags}
        updateTags={updateTags}
        editFunctionTagInfo={editFunctionTagInfo}
        cancelFunctionModal={cancelFunctionModal}
        functionModalVisible={functionModalVisible}
      />
      <AddFunctionParameterSeperator tags={tags} updateTags={updateTags} />
      <AddField
        tags={tags}
        updateTags={updateTags}
        fieldTags={fieldTags}
        updateFieldTags={updateFieldTags}
        currentModuleID={currentModuleID}
        selectFieldModalVisible={selectFieldModalVisible}
        showFieldModal={showFieldModal}
        cancelFieldModal={cancelFieldModal}
        currentFieldID={currentFieldID}
        editFieldTagInfo={editFieldTagInfo}
      />

      {/* <Flex
        flexDirection={"row"}
        style={{ marginTop: 5, marginBottom: 10, gap: 10 }}
      >
        <AddFunctionButton
          editFieldTagInfo={editFieldTagInfo}
          updateTags={updateTags}
          tags={tags}
          functionModalVisible={functionModalVisible}
          showFunctionModal={showFunctionModal}
          cancelFunctionModal={cancelFunctionModal}
          editFunctionTagInfo={editFunctionTagInfo}
        />
        <AddFunctionParameterSeperator tags={tags} updateTags={updateTags} />
      </Flex> */}

      <EraseButton
        updateTags={updateTags}
        updateEditFieldTagInfo={updateEditFieldTagInfo}
        addChange={addChange}
      />
    </Flex>
  );
};

export default Buttons;
