import Flex from "../../../../../../../../helpers/components/Flex";
import { Button, Tooltip, Tag } from "antd";
import useFunctionTags from "../../hooks/useFunctionTags";
import FunctionsModal from "../../modals/FunctionsModal";

const AddFunction = ({
  tags,
  updateTags,
  functionModalVisible,
  cancelFunctionModal,
  editFunctionTagInfo,
}) => {
  const functionTags = useFunctionTags();

  const addFunction = (id, index) => {
    if (index >= 0) {
      const newTags = [...tags];
      newTags[index] = id;
      updateTags(newTags);
    } else {
      if (id !== "#M_PI#") {
        updateTags([...tags, id, "("]);
      } else {
        updateTags([...tags, id]);
      }
    }

    cancelFunctionModal();
  };

  const deleteFunction = (index) => {
    if (index >= 0) {
      let newTags = [...tags];
      newTags.splice(index, 1);
      updateTags(newTags);
    }
    cancelFunctionModal();
  };

  return (
    <Flex flexDirection={"row"} style={{ gap: 5, marginTop: 3 }}>
      {Object.entries(functionTags).map(([key, value]) => (
        <Tooltip key={key} placement="bottom" title={value.tooltip}>
          <Tag
            key={key}
            color="yellow"
            style={{
              border: "dashed 0.5px #E5E4E2",
              //backgroundColor: "#feffe6",
              backgroundColor: "transparent",
              fontSize: 12,
              heigth: 22,
              padding: 8,
              paddingTop: 5,
              paddingBottom: 5,
            }}
            onClick={() => addFunction(key)}
          >
            {value.title}
          </Tag>
        </Tooltip>
      ))}
      {functionModalVisible && (
        <FunctionsModal
          visible={functionModalVisible}
          addFunction={addFunction}
          deleteFunction={deleteFunction}
          onCancel={cancelFunctionModal}
          editFunctionTagInfo={editFunctionTagInfo}
        />
      )}
    </Flex>
  );
};

export default AddFunction;
