import { Select } from "antd";
import { useCommonTitlesStore } from "../../../../../../store/useStores";

const { Option } = Select;

const DependentStaticList = ({
  value,
  id,
  fieldID,
  staticOptions,
  addChange,
  title,
  config,
}) => {
  let newValue = value?.[fieldID] ?? [];
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  return (
    <div className="select">
      <Select
        mode={config === "filters" && "multiple"}
        allowClear
        style={{
          width: 278,
          marginLeft: 7,
          marginTop: 4,
        }}
        showSearch={true}
        filterOption={(iv, op) =>
          op.value.toLocaleLowerCase().includes((iv || "").toLocaleLowerCase())
        }
        onChange={(e) => addChange(id, { [fieldID]: e }, null)}
        placeholder={title}
        defaultValue={newValue}
        dropdownStyle={{ overflowY: "auto" }}
        virtual={false}
      >
        <Option value=""></Option>
        {config === "filters" && (
          <Option value={"NV"} className="option">
            {commonTitles?.["CO|1|NOVALUE"]}
          </Option>
        )}
        {staticOptions?.[fieldID] &&
          Object.entries(staticOptions[fieldID]).map(([key, value]) => {
            return (
              <Option key={value?.ORDER} className="option" value={key}>
                {value?.TITLE}
              </Option>
            );
          })}
      </Select>
    </div>
  );
};

export default DependentStaticList;
