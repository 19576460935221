import Flex from "../../../../../../helpers/components/Flex";
import { Select } from "antd";
import { useModuleStore } from "../../../../../../store/useStores";

const { Option } = Select;

const Totals = ({ data, addChange, fields, config, staticOptions, type }) => {
  let defaultValue = [];
  const numberTypes = useModuleStore((state) => state.numberTypes);

  if (config === "edit") {
    if (data.F_TOTALS) {
      if (
        data.F_TOTALS.length === 1 &&
        (!data.F_TOTALS?.[0] || data.F_TOTALS?.[0] === "N")
      ) {
        defaultValue = [];
      } else {
        defaultValue = data.F_TOTALS;
      }
    }
  }

  let options = [{ title: staticOptions[32].CNT.TITLE, value: "CNT" }];

  if (numberTypes.includes(type)) {
    options = [
      { title: staticOptions[32].AVG.TITLE, value: "AVG" },
      { title: staticOptions[32].CNT.TITLE, value: "CNT" },
      { title: staticOptions[32].MAX.TITLE, value: "MAX" },
      { title: staticOptions[32].MIN.TITLE, value: "MIN" },
      { title: staticOptions[32].SUM.TITLE, value: "SUM" },
    ];
  }

  return (
    <Flex
      flexDirection={"row"}
      style={{ gap: "0.5rem", justifyItems: "center" }}
    >
      <p
        style={{
          display: "flex",
          width: 180,
          justifyContent: "flex-end",
        }}
      >
        {fields[32].TITLE}:
      </p>
      <div className="select">
        <Select
          style={{
            width: 300,
            marginLeft: 15,
            marginBottom: "1rem",
          }}
          mode="multiple"
          showSearch={true}
          onChange={(e) => {
            addChange("F_TOTALS", e);
          }}
          filterOption={(iv, op) => {
            let opToUse = op?.children ? op.children : "";
            return opToUse.toLowerCase().includes(iv.toLowerCase());
          }}
          defaultValue={defaultValue}
          dropdownStyle={{ overflowY: "auto" }}
          virtual={false}
        >
          <Option className="option" value={[]} />
          {options.map((option) => (
            <Option
              key={option?.value}
              className="option"
              value={option?.value}
            >
              {option?.title}
            </Option>
          ))}
          {/* <Option key={"AVG"} className="option" value={"AVG"}>
            {staticOptions[32].AVG.TITLE}
          </Option>
          <Option key={"CNT"} className="option" value={"CNT"}>
            {staticOptions[32].CNT.TITLE}
          </Option>
          <Option key={"MAX"} className="option" value={"MAX"}>
            {staticOptions[32].MAX.TITLE}
          </Option>
          <Option key={"MIN"} className="option" value={"MIN"}>
            {staticOptions[32].MIN.TITLE}
          </Option>
          <Option key={"SUM"} className="option" value={"SUM"}>
            {staticOptions[32].SUM.TITLE}
          </Option> */}
        </Select>
      </div>
    </Flex>
  );
};

export default Totals;
