import { PlusOutlined } from "@ant-design/icons";
import { useCommonTitlesStore } from "../../../../../../../../store/useStores";
import { Button, Tag } from "antd";
import { addFieldButtonStyle } from "../../styles/TagItemStyles";

const AddFunctionParameterSeperator = ({ tags, updateTags }) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const handleChange = () => {
    let newTags = [...tags];
    newTags.push(",");
    updateTags(newTags);
  };

  return (
    <Tag
      icon={
        <PlusOutlined
          style={{
            fontSize: 10,
          }}
          onClick={() => handleChange()}
        />
      }
      color="yellow"
      style={{
        border: "dashed 0.5px #E5E4E2",
        //backgroundColor: "#feffe6",
        backgroundColor: "transparent",
        fontSize: 12,
        heigth: 22,
        padding: 8,
        paddingTop: 5,
        paddingBottom: 5,
        width: "max-content",
      }}
      onClick={() => handleChange()}
    >
      {commonTitles["CO|1|ADDPARASEP"]}
    </Tag>
  );
};

export default AddFunctionParameterSeperator;
