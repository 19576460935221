import { useState } from "react";
import Button from "@mui/material/Button";
import ReactTooltip from "react-tooltip";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import {
  useAuthStore,
  useCommonTitlesStore,
} from "../../../../store/useStores";
import KPIsModal from "./KPIsModal";

const KPIsButton = ({ kpis }) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const bannerTextColor = useAuthStore((state) => state.bannerTextColor);
  const [kpisModalVisible, setKpisModalVisible] = useState(false);

  const showKpisModal = () => {
    setKpisModalVisible(true);
  };

  const cancelKpisModal = () => {
    setKpisModalVisible(false);
  };

  if (!kpis?.length) {
    return null;
  }

  return (
    <div className="kpis">
      <a data-tip={"KPIs"}>
        <Button sx={{ color: bannerTextColor }} onClick={showKpisModal}>
          {<CrisisAlertIcon />}
        </Button>
      </a>
      <ReactTooltip place="bottom" />
      <KPIsModal
        visible={kpisModalVisible}
        kpis={kpis}
        onCancel={cancelKpisModal}
      />
    </div>
  );
};

export default KPIsButton;
