import { Layout } from "antd";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableHead, TableBody } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { Modal, Button } from "antd";
import {
  useChartStore,
  useCommonTitlesStore,
} from "../../../../store/useStores";
import createDataForTable from "../../UC helpers/createDataForTable";
import countTableRowTotal from "../../UC helpers/countTableRowTotal";
import countTableColumnTotal from "../../UC helpers/countTableColumnTotal";

const { Content } = Layout;

const UltimateChartTableModalContent = ({ dataToShow, config }) => {
  const chartType = useChartStore((state) => state.chartType);
  const groupField = useChartStore((state) => state.groupField);
  const displayFunction = useChartStore((state) => state.displayFunction);

  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  dataToShow = createDataForTable(dataToShow, chartType, config);

  const displayFunctionTitles = {
    count: commonTitles.count,
    pctCount: commonTitles.pctCount,
    sum: commonTitles.sum,
    pctSum: commonTitles.pctSum,
  };

  const headers = Object.keys(dataToShow[0]).filter(
    (item) => item !== "id" && item !== "sum"
  );

  const bodyData = [];

  dataToShow.forEach((item) => {
    let newItem = Object.fromEntries(
      Object.entries(item).filter(([key]) => key !== "sum")
    );
    bodyData.push(newItem);
  });

  return (
    <Layout
      style={{
        borderRadius: 10,
        overflow: "hidden",
        height: "max-content",
        width: "100%",
      }}
    >
      <Content
        style={{
          padding: 10,
          maxHeight: "70vh",
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "70vw",
        }}
      >
        <TableContainer component={Paper} className="testing_table">
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    backgroundColor: "#f0f2f5",
                    position: "sticky",
                    left: 0,
                    zIndex: 5,
                  }}
                />
                {headers.map((header) => (
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: "rgb(206, 223, 252)",
                      fontSize: 12,
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                    }}
                  >
                    {!groupField
                      ? displayFunctionTitles?.[displayFunction]
                      : header}
                  </TableCell>
                ))}
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: "rgb(157, 188, 243)",
                    fontSize: 12,
                    fontWeight: 600,
                    whiteSpace: "nowrap",
                    display: !groupField && "none",
                  }}
                >
                  {commonTitles["CO|1|TOTAL"]}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bodyData.map((item) => {
                return (
                  <TableRow>
                    <TableCell
                      key={item?.id}
                      align="center"
                      style={{
                        backgroundColor: "rgb(206, 223, 252)",
                        fontWeight: 600,
                        width: "max-content",
                        position: "sticky",
                        left: 0,
                      }}
                    >
                      {item?.id}
                    </TableCell>
                    {headers.map((header, index) => (
                      <TableCell
                        key={`${index}-${header}`}
                        align="center"
                        style={{
                          color:
                            item?.[header] || (item?.header !== 0 && "#E5E4E2"),
                        }}
                      >
                        {item?.[header] || item?.header !== 0
                          ? Math.round(item?.[header] * 100) / 100
                          : null}
                      </TableCell>
                    ))}
                    {groupField && (
                      <TableCell
                        align="center"
                        style={{
                          backgroundColor: "rgb(206, 223, 252)",
                          borderBottomColor: "rgb(75, 75, 76)",
                        }}
                      >
                        {countTableRowTotal(item, headers)}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: "rgb(157, 188, 243)",
                    fontWeight: 600,
                    width: "max-content",
                    position: "sticky",
                    left: 0,
                  }}
                >
                  {commonTitles["CO|1|TOTAL"]}
                </TableCell>
                {headers.map((header, index) => (
                  <TableCell
                    key={`${index}-${header}`}
                    align="center"
                    style={{
                      backgroundColor: "rgb(206, 223, 252)",
                    }}
                  >
                    {countTableColumnTotal(bodyData, header)}
                  </TableCell>
                ))}
                <TableCell
                  style={{
                    backgroundColor: "#f0f2f5",
                    position: "sticky",
                    left: 0,
                    zIndex: 5,
                    display: !groupField && "none",
                  }}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Content>
    </Layout>
  );
};

export const UltimateChartTableModal = ({
  dataToShow,
  visible,
  config,
  onCancel,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  return (
    <Modal
      title={commonTitles["CO|1|CDATATABLE"]}
      open={visible}
      onCancel={onCancel}
      width="max-content"
      style={{ marginTop: -60.5, minWidth: 800 }}
      footer={[
        <Button
          key="back"
          style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
          onClick={onCancel}
        >
          {commonTitles["CO|1|CLOSEBTN"]}
        </Button>,
      ]}
    >
      <UltimateChartTableModalContent dataToShow={dataToShow} config={config} />
    </Modal>
  );
};
