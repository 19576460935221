import Flex from "../../../../../../../../helpers/components/Flex";
import { Button, Tag, Row, Col } from "antd";
import { useState, useEffect } from "react";
import {
  useAuthStore,
  useModuleStore,
} from "../../../../../../../../store/useStores";
import apiGetModuleFields from "../../../../../../../../api/endpoints/apiGetModuleFields";
import apiGetModulesList from "../../../../../../../../api/endpoints/apiGetModulesList";
import SelectFieldModal from "../../modals/selectFieldModal/SelectFieldModal";
import useWindowDimensions from "../../../../../../../ultimateTable/hooks/useWindowDimensions";

const AddField = ({
  tags,
  updateTags,
  fieldTags,
  updateFieldTags,
  currentModuleID,
  currentFieldID,
  selectFieldModalVisible,
  editFieldTagInfo,
  cancelFieldModal,
}) => {
  const [fieldOptions, setFieldOptions] = useState([]);
  const [moduleTitle, setModuleTitle] = useState([]);
  const baseURL = useAuthStore((state) => state.baseURL);
  const numberTypes = useModuleStore((state) => state.numberTypes);
  const { width } = useWindowDimensions();

  useEffect(async () => {
    if (currentModuleID) {
      const { modules } = await apiGetModulesList(baseURL);
      const tempModuleTitle =
        modules.filter((module) => module.M_ID === currentModuleID)?.[0]
          ?.T_TITLE ?? "";
      setModuleTitle(tempModuleTitle);

      const { rows } = await apiGetModuleFields(baseURL, currentModuleID);
      setFieldOptions(
        rows.filter(
          (item) =>
            numberTypes.includes(item?.F_TYPE) && item?.F_ID !== currentFieldID
        )
      );
    }
  }, []);

  const addField = (id, title, moduleTitle, index) => {
    console.log("Button", { id, title, moduleTitle, index });
    if (index >= 0) {
      const newTags = [...tags];
      newTags[index] = id;
      updateTags(newTags);
    } else {
      updateTags([...tags, id]);
    }

    updateFieldTags({
      ...fieldTags,
      [id]: { fieldTitle: title, moduleTitle: moduleTitle },
    });

    cancelFieldModal();
  };

  const deleteField = (index) => {
    if (index >= 0) {
      let newTags = [...tags];
      newTags.splice(index, 1);
      updateTags(newTags);
    }
    cancelFieldModal();
  };

  return (
    <Flex flexDirection={"row"} style={{ gap: 8, marginTop: 3 }}>
      <Row gutter={[20, 10]} justify={width < 1450 ? "center" : "left"}>
        {fieldOptions.map((field) => (
          <Col
            className="gutter-row"
            //span={width < 1450 ? 20 : 10}
            span={8}
            key={field?.F_ID}
          >
            <Tag
              key={field?.F_ID}
              style={{
                border: "dashed 0.5px #E5E4E2",
                backgroundColor: "#e6f4ff",
                fontSize: 12,
                heigth: 22,
                padding: 8,
                paddingTop: 5,
                paddingBottom: 5,
                width: 160,
              }}
              color="blue"
              onClick={() =>
                addField(`[${field?.F_ID}]`, field?.T_TITLE, moduleTitle, -1)
              }
            >
              {field?.T_TITLE}
            </Tag>
          </Col>
        ))}
      </Row>
      {selectFieldModalVisible && (
        <SelectFieldModal
          visible={selectFieldModalVisible}
          addField={addField}
          deleteField={deleteField}
          onCancel={cancelFieldModal}
          editFieldTagInfo={editFieldTagInfo}
          currentFieldID={currentFieldID}
          currentModuleID={currentModuleID}
        />
      )}
    </Flex>
  );
};

export default AddField;
