const withGroupNoDateSum = (
  columns,
  data,
  xAxisFieldToUse,
  dateFormatToUse,
  commonTitles,
  groupField,
  foreignKeyValues,
  staticListColumns,
  dependentColumns,
  countColumns,
  numberAlertColumns,
  multipleValuesColumns,
  childxAxisField,
  xValuesUsed,
  yValuesUsed,
  noDateDataColumnStatus,
  staticOptions
) => {
  const isAlert =
    columns?.filter((column) => column?.F_ID === xAxisFieldToUse)?.[0]
      ?.F_RENDER === "ALERT"
      ? true
      : false;

  const xAxisFieldColumn = columns?.filter(
    (column) => column.F_ID === xAxisFieldToUse
  )[0];

  let xAxisFieldName;
  let childxAxisFieldIndex;
  let childxAxisNumber;

  if (childxAxisField) {
    const childTitle = xAxisFieldColumn.DEP_FIELDS?.filter(
      (field) => field.F_ID === childxAxisField
    ).map((item) => item.TITLE);
    xAxisFieldName = `${xAxisFieldColumn.T_TITLE} - ${childTitle}`;
    childxAxisNumber = xAxisFieldColumn.DEP_FIELDS?.length;
    const dependentFields = columns?.filter(
      (column) => column.F_ID === xAxisFieldToUse
    )[0].DEP_FIELDS;

    childxAxisFieldIndex = dependentFields.findIndex((object) => {
      return object.F_ID === childxAxisField;
    });
  } else {
    xAxisFieldName = xAxisFieldColumn.T_TITLE;
  }

  let xAxisFieldValues = data?.map((item) => {
    if (multipleValuesColumns.includes(xAxisFieldToUse)) {
      return item[`${xAxisFieldToUse}-server`];
    } else if (dependentColumns.includes(xAxisFieldToUse)) {
      if (item[xAxisFieldToUse] && item[xAxisFieldToUse].includes(",")) {
        const tempValue = item[xAxisFieldToUse].split(", ");
        const finalValue = tempValue[childxAxisFieldIndex];
        return finalValue;
      } else {
        return item[xAxisFieldToUse];
      }
    } else if (numberAlertColumns.includes(xAxisFieldToUse)) {
      return item[xAxisFieldToUse]?.value;
    } else return item[xAxisFieldToUse];
  });

  let uniquexAxisFieldValues = [];
  xAxisFieldValues.forEach((item) => {
    if (
      item &&
      staticListColumns.includes(xAxisFieldToUse) &&
      typeof item === "object"
    ) {
      item = item?.view;
    }
    if (
      item &&
      item?.includes(",") &&
      !countColumns.includes(xAxisFieldToUse)
    ) {
      const myArray = item.split(",");
      myArray.forEach((value) => {
        if (value && !uniquexAxisFieldValues.includes(value)) {
          uniquexAxisFieldValues.push(value);
        }
      });
    } else {
      if (item && !uniquexAxisFieldValues.includes(item)) {
        uniquexAxisFieldValues.push(item);
      }
    }
  });

  if (multipleValuesColumns.includes(xAxisFieldToUse)) {
    uniquexAxisFieldValues = uniquexAxisFieldValues.map((value) => {
      if (foreignKeyValues[xAxisFieldToUse][value]?.view.includes(",")) {
        const myArray =
          foreignKeyValues[xAxisFieldToUse][value]?.view.split(", ");
        return myArray[0];
      } else {
        return foreignKeyValues[xAxisFieldToUse][value]?.view;
      }
    });
  }

  if (staticListColumns.includes(xAxisFieldToUse)) {
    uniquexAxisFieldValues = Object.values(staticOptions?.[xAxisFieldToUse])
      .sort((a, b) => parseInt(a?.ORDER) - parseInt(b?.ORDER))
      .map((value) => value?.TITLE);
  }

  if (!noDateDataColumnStatus) {
    uniquexAxisFieldValues.push(
      `${commonTitles["CO|1|NODATAFOR"]} ${xAxisFieldName}`
    );
  }

  //console.log("uniquexAxisFieldValues: ", uniquexAxisFieldValues);

  const isStaticList =
    columns?.filter((column) => column?.F_ID === xAxisFieldToUse)?.[0]
      ?.F_TYPE === "6"
      ? true
      : false;

  const isMultiple =
    columns?.filter((column) => column?.F_ID === xAxisFieldToUse)?.[0]
      ?.F_TYPE === "28"
      ? true
      : false;

  const isDependend =
    columns?.filter((column) => column?.F_ID === xAxisFieldToUse)?.[0]
      ?.F_TYPE === "30"
      ? true
      : false;

  let childGroupField;
  let groupFieldToUse = groupField;

  if (groupField?.includes("-")) {
    let tempGroupField = groupField.split(" - ");

    groupFieldToUse = tempGroupField[0];
    childGroupField = tempGroupField[1];
  }

  const groupFieldColumn = columns?.filter(
    (column) => column.F_ID === groupFieldToUse
  )[0];

  let groupFieldName;
  let childGroupFieldIndex;
  let childGroupNumber;
  if (childGroupField) {
    const childTitle = groupFieldColumn?.DEP_FIELDS?.filter(
      (field) => field.F_ID === childGroupField
    ).map((item) => item.TITLE);
    groupFieldName = `${groupFieldColumn?.T_TITLE} - ${childTitle}`;
    childGroupNumber = groupFieldColumn?.DEP_FIELDS?.length;
    const dependentFields = columns?.filter(
      (column) => column.F_ID === groupFieldToUse
    )[0]?.DEP_FIELDS;

    childGroupFieldIndex = dependentFields.findIndex((object) => {
      return object.F_ID === childGroupField;
    });
  } else {
    groupFieldName = groupFieldColumn?.T_TITLE;
  }

  const isGroupFieldAlert =
    columns?.filter((column) => column?.F_ID === groupFieldToUse)?.[0]
      ?.F_RENDER === "ALERT"
      ? true
      : false;

  let dailyCount = {};

  for (let i = 0; i < uniquexAxisFieldValues.length; i++) {
    let counter = 0;
    let pseudoCounter = 0;
    //let noGroupFieldDataForDate = true;
    for (let z = 0; z < data.length; z++) {
      let dateValue = data[z][xAxisFieldToUse] ?? "";

      if (isAlert) {
        dateValue = data[z][xAxisFieldToUse]?.value;
      } else if (isStaticList) {
        dateValue = data[z][xAxisFieldToUse]?.view;
      }

      if (!dateValue) {
        dateValue = `${commonTitles["CO|1|NODATAFOR"]} ${xAxisFieldName}`;
      }

      if (
        dateValue === uniquexAxisFieldValues[i] ||
        (isMultiple &&
          dateValue !== `${commonTitles["CO|1|NODATAFOR"]} ${xAxisFieldName}` &&
          dateValue.includes(uniquexAxisFieldValues[i])) ||
        (isDependend &&
          dateValue !== `${commonTitles["CO|1|NODATAFOR"]} ${xAxisFieldName}` &&
          dateValue.includes(uniquexAxisFieldValues[i]))
      ) {
        let groupFieldValue = isGroupFieldAlert
          ? data[z][groupFieldToUse]?.value
          : data[z][groupFieldToUse];

        if (
          groupFieldValue &&
          staticListColumns.includes(groupFieldToUse) &&
          typeof groupFieldValue === "object"
        ) {
          groupFieldValue = groupFieldValue.view;
        }

        if (groupFieldValue && dependentColumns.includes(groupFieldToUse)) {
          if (groupFieldValue.includes(",")) {
            let tempArray = groupFieldValue.split(", ");
            //data[z][groupFieldToUse] = tempArray[childGroupFieldIndex];
            if (tempArray[childGroupFieldIndex]) {
              //noGroupFieldDataForDate = false;
              counter += parseFloat(tempArray[childGroupFieldIndex]);
            }
          } else {
            if (groupFieldValue) {
              //noGroupFieldDataForDate = false;
              counter += parseFloat(groupFieldValue);
            }
          }
        } else {
          if (groupFieldValue) {
            //noGroupFieldDataForDate = false;
            counter += parseFloat(groupFieldValue);
          }
        }
      }

      dailyCount = {
        ...dailyCount,
        [uniquexAxisFieldValues[i]]: {
          [commonTitles?.sum]: counter,
        },
      };
    }
  }

  let totalSum = 0;

  Object.entries(dailyCount).forEach(([key, value]) => {
    let sum = 0;

    Object.values(value).forEach((item) => {
      sum += item;
      totalSum += item;
    });

    if (sum || !xValuesUsed) {
      dailyCount[key] = { ...dailyCount[key], sum: sum };
    } else {
      if (xValuesUsed) {
        delete dailyCount[key];
      }
    }
  });

  return {
    dailyCount: { ...dailyCount, totalSum: totalSum },
    monthlyCount: {},
    yearlyCount: {},
  };
};

export default withGroupNoDateSum;
