import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableHead } from "@mui/material";
import "./css/ultimateTable.css";
import { Checkbox, Tooltip } from "antd";
import { useAuthStore, useCommonTitlesStore } from "../../store/useStores";
import UltimateTableTotals from "./oldFiles/UltimateTableTotalsOld";
import { EditFieldModal } from "../administration/modules/fields/modals/EditFieldModal";
import { useEffect, useState, createRef, useRef } from "react";
import displayResponseMessage from "../../helpers/functions/displayResponseMessage";
import axios from "axios";
import htmlCodesRemover from "./helpers/htmlCodesRemover";
import apiGetModuleFields from "../../api/endpoints/apiGetModuleFields";
import Flex from "../../helpers/components/Flex";
import chooseHeaderCellStyle from "./helpers/chooseHeaderCellStyle";
import createFormula from "./helpers/createFormula";

const DEFAULT_MIN_WIDTH_CELL = 50;
const DEFAULT_MAX_WIDTH_CELL = 5000;

const UltimateTableHead = ({
  columns,
  dataToUse,
  selection,
  selectedItems,
  selectAll,
  sort,
  decideSortIcon,
  config,
  privileges,
  totals = {},
  totalRowWidth,
  showTotals,
  moduleID,
  frozenColumns = [],
  allDataToUse,
}) => {
  const role = useAuthStore((state) => state.role);
  const baseURL = useAuthStore((state) => state.baseURL);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const [fieldSettingsModalVisible, setFieldSettingsModalVisible] =
    useState(false);

  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState({});
  const [headData, setHeadData] = useState([]);
  const [refModules, setRefModules] = useState([]);
  const [fields, setFields] = useState({});
  const [staticOptions, setStaticOptions] = useState({});

  const [fieldIndexToEdit, setFieldIndexToEdit] = useState(null);
  const [fieldToEdit, setFieldToEdit] = useState({});

  const [tooltipVisible, setTooltipVisible] = useState({});

  const [columnInfoChanges, setColumnInfoChanges] = useState({});
  const [changesReady, setChangesReady] = useState(false);

  let viewRights = { firstBox: true };
  columns.forEach((column) => {
    if (role === "A") {
      viewRights = { ...viewRights, [column.F_ID]: true };
    } else if (Object.keys(column).includes("canview")) {
      viewRights = {
        ...viewRights,
        [column.F_ID]: column.canview,
      };
    } else {
      viewRights = {
        ...viewRights,
        [column.F_ID]: privileges?.canView,
      };
    }
  });

  const onSave = async (changes = {}, mode) => {
    const url = `${baseURL}/App_fields_api/save`;

    let changesForServer = {};
    if (changes?.F_TYPE) {
      if (changes.F_TYPE === fieldToEdit.F_TYPE) {
        changesForServer = { ...fieldToEdit, ...changes };
      } else {
        if (changes?.T_TITLE && changes?.T_TITLE !== "") {
          changesForServer = { ...changes };
        } else {
          changesForServer = {
            T_TITLE: fieldToEdit.T_TITLE,
            ...changes,
          };
        }
      }
    } else {
      changesForServer = { ...fieldToEdit, ...changes };
    }

    let id = fieldToEdit?.F_ID;
    if (mode === "quickWidthChange") {
      id = changes?.F_ID;
    }

    try {
      const response = await axios.post(
        url,
        {
          id: id,
          ...changesForServer,
        },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("App_fields_api/save: response: ", response?.data);

      if (response?.data?.STATUS === "Success") {
        setFieldSettingsModalVisible(false);

        if (mode !== "quickWidthChange") {
          window.location.reload();
          displayResponseMessage(commonTitles["CO|1|REGMSG1"], 3, "success");
        }
      } else {
        const responseMsg = response?.data?.MESSAGE ?? {};
        let template = commonTitles[responseMsg.ID];

        Object.entries(responseMsg)
          .filter(([key]) => key !== "ID")
          .forEach(([key, value]) => {
            var re = new RegExp(key, "g");
            template = template.replace(re, value);
          });
        displayResponseMessage(template, 5, "warning");
      }
    } catch (err) {
      displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
    }
  };

  const handleCancel = () => {
    setFieldSettingsModalVisible(false);
  };

  const fetchData = async (fieldID) => {
    const serverData = await apiGetModuleFields(baseURL, moduleID);

    setFields(serverData.fields);
    setStaticOptions(serverData.staticoptions);
    setRefModules(serverData.refmodules);
    setHeadData([
      { id: serverData.fields[4].FIELD, title: serverData.fields[4].TITLE },
      { id: serverData.fields[5].FIELD, title: serverData.fields[5].TITLE },
      { id: serverData.fields[7].FIELD, title: serverData.fields[7].TITLE },
      { id: serverData.fields[8].FIELD, title: serverData.fields[8].TITLE },
      { id: serverData.fields[9].FIELD, title: serverData.fields[9].TITLE },
      { id: serverData.fields[10].FIELD, title: serverData.fields[10].TITLE },
      { id: serverData.fields[11].FIELD, title: serverData.fields[11].TITLE },
      { id: serverData.fields[32].FIELD, title: serverData.fields[32].TITLE },
    ]);

    const tempData = serverData.rows.map((item) => ({
      ...item,
      T_TITLE: htmlCodesRemover(item.T_TITLE) ?? item.T_TITLE,
    }));

    setData(tempData);
    setModalData(tempData.filter((item) => item.F_ID === fieldID)?.[0] ?? {});
    setFieldToEdit(tempData.filter((item) => item.F_ID === fieldID)?.[0] ?? {});
  };

  const openFieldSettingsModal = async (fieldID) => {
    if (role === "A" && config !== "alerts") {
      setFieldIndexToEdit(fieldID);
      await fetchData(fieldID);
      setFieldSettingsModalVisible(true);
    }
  };

  let newColumns = [
    { F_ID: "firstBox", F_LIST: "Y", width: 35, F_ALIGN: "Center" },
  ];

  columns
    .filter((column) => column.F_LIST === "Y")
    .forEach((column) => {
      newColumns.push({
        ...column,
        width:
          column.F_WIDTH && column.F_WIDTH !== "0"
            ? parseInt(column.F_WIDTH)
            : 200,
      });
    });

  const [columnRefs, setColumnRefs] = useState(
    newColumns?.map(() => createRef())
  );
  const isResizing = useRef(-1);

  useEffect(() => {
    if (config !== "alerts") {
      document.onmousemove = handleOnMouseMove;
      document.onmouseup = handleOnMouseUp;
      return () => {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    }
  }, []);

  const adjustWidthColumn = (index, width) => {
    const minWidth = newColumns[index]?.minWidth ?? DEFAULT_MIN_WIDTH_CELL;
    const maxWidth = columnRefs[index]?.maxWidth ?? DEFAULT_MAX_WIDTH_CELL;
    const newWidth =
      width > maxWidth ? maxWidth : width < minWidth ? minWidth : width;

    columnRefs[index].current.parentElement.style.width = newWidth + "px";
    setColumnInfoChanges({
      index: index - 1,
      width: newWidth,
      id: newColumns[index]?.F_ID,
    });
  };

  const handleOnMouseMove = (e) => {
    if (isResizing.current >= 0) {
      const newWidth =
        e.clientX -
        columnRefs[
          isResizing.current
        ].current.parentElement?.getBoundingClientRect().left;
      adjustWidthColumn(isResizing.current, newWidth);
    }
  };

  const setCursorDocument = (isResizing) => {
    document.body.style.cursor = isResizing ? "col-resize" : "auto";
  };

  const handleOnMouseUp = () => {
    isResizing.current = -1;
    setChangesReady(!changesReady);
    setCursorDocument(false);
  };

  useEffect(async () => {
    if (config !== "alerts" && Object.keys(columnInfoChanges).length) {
      const serverData = await apiGetModuleFields(baseURL, moduleID);

      const tempData = serverData.rows.map((item) => ({
        ...item,
        T_TITLE: htmlCodesRemover(item.T_TITLE) ?? item.T_TITLE,
      }));

      if (Object.keys(columnInfoChanges).length) {
        const columnToEdit = tempData.filter(
          (item) => item.F_ID === columnInfoChanges?.id
        )?.[0];
        const changes = {
          ...columnToEdit,
          F_WIDTH: columnInfoChanges?.width?.toString(),
        };

        onSave(changes, "quickWidthChange");
      }
    }
  }, [changesReady]);

  const onClickResizeColumn = async (index) => {
    isResizing.current = index;
    setCursorDocument(true);
  };

  const TooltipTotals = ({ id }) => {
    if (!totals?.[id]) {
      return null;
    }

    const newValue = Object.keys(totals?.[id])?.map((key) => (
      <p key={totals?.titles?.[key]} style={{ fontSize: 11 }}>
        {totals?.titles?.[key]}: {totals?.[id]?.[key]}
      </p>
    ));

    return newValue;
  };

  return (
    <TableHead
      className="tableHead"
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 5,
      }}
    >
      {/* {config !== "alerts" && (
        <UltimateTableTotals
          columns={columns}
          privileges={privileges}
          selection={selection}
          totals={totals}
          totalRowWidth={totalRowWidth}
          showTotals={showTotals}
        />
      )} */}
      <TableRow>
        {selection && (
          <TableCell
            key={`header - checkbox`}
            padding="checkbox"
            width={15}
            align="left"
            style={{
              paddingLeft: 13,
              position: "sticky",
              left: 0,
              background: "white",
              zIndex: 3,
            }}
          >
            <Checkbox
              checked={selectedItems.length === allDataToUse.length}
              onChange={selectAll}
            />
          </TableCell>
        )}
        {newColumns
          ?.filter(
            (column) => viewRights[column.F_ID] && column?.F_LIST === "Y"
          )
          ?.map(
            (
              { T_TITLE, F_ID, F_ALIGN, INFO, F_CALC, F_TYPE, width },
              colIndex
            ) => {
              // if (colIndex === 0) {
              //   return (
              //     <TableCell
              //       key={F_ID}
              //       width={30}
              //       style={chooseHeaderCellStyle(
              //         width,
              //         colIndex,
              //         frozenColumns,
              //         newColumns,
              //         selection
              //       )}
              //     />
              //   );
              // } else {
              return (
                <Tooltip
                  key={`header - ${F_ID}`}
                  placement="bottom"
                  title={
                    config === "alerts" ||
                    !tooltipVisible ||
                    (!INFO && role !== "A") ||
                    colIndex === 0 ? null : (
                      <Flex flexDirection={"column"} style={{ gap: 5 }}>
                        <p
                          style={{
                            fontSize: 13,
                            fontWeight: 600,
                            marginTop: totals?.[F_ID] && 10,
                          }}
                        >
                          {T_TITLE}
                        </p>
                        {/* <TooltipTotals id={F_ID} /> */}
                        {INFO && (
                          <p
                            style={{
                              fontSize: 11,
                              marginTop: totals?.[F_ID] && 10,
                            }}
                          >
                            {INFO}
                          </p>
                        )}
                        {F_TYPE === "116" && (
                          <p
                            style={{
                              fontSize: 13,
                              fontWeight: 600,
                              marginTop: totals?.[F_ID] && 10,
                            }}
                          >
                            {createFormula(F_CALC, newColumns)}
                          </p>
                        )}
                        {role === "A" && (
                          <i
                            style={{
                              fontSize: 11,
                              marginTop: 10,
                            }}
                          >
                            {commonTitles["CO|1|RCLKMSG"]}
                          </i>
                        )}
                      </Flex>
                    )
                  }
                >
                  <TableCell
                    id={F_ID}
                    key={`header - ${F_ID}`}
                    //className="header"
                    className="tableCell resizable"
                    onContextMenu={(e) => {
                      e.preventDefault();
                      openFieldSettingsModal(F_ID);
                    }}
                    align={F_ALIGN.toLowerCase()}
                    width={width}
                    style={chooseHeaderCellStyle(
                      width,
                      F_ID,
                      frozenColumns,
                      newColumns,
                      selection
                    )}
                    onMouseEnter={() => setTooltipVisible(true)}
                  >
                    <p
                      onClick={(e) => {
                        if (e.type === "click") {
                          sort(F_ID);
                          setTooltipVisible(false);
                        }
                      }}
                      style={{
                        paddingRight: 14,
                        paddingLeft: 14,
                        display: colIndex === 0 && "none",
                      }}
                    >
                      {decideSortIcon(F_ID, T_TITLE)}
                    </p>

                    {config !== "alerts" && colIndex !== 0 && (
                      <div
                        onMouseDown={() => onClickResizeColumn(colIndex)}
                        ref={columnRefs[colIndex]}
                        className={"resizeLine"}
                      />
                    )}
                  </TableCell>
                </Tooltip>
              );
            }
            // }
          )}
      </TableRow>
      {fieldSettingsModalVisible && (
        <EditFieldModal
          visible={fieldSettingsModalVisible}
          headers={headData}
          data={modalData}
          bodyData={data}
          staticOptions={staticOptions}
          fields={fields}
          refModules={refModules}
          onSave={onSave}
          onCancel={handleCancel}
          fieldIndexToEdit={fieldIndexToEdit}
          config={"instantEdit"}
          currentModuleID={moduleID}
        />
      )}
    </TableHead>
  );
};

export default UltimateTableHead;
