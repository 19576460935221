import React, { useEffect, useState } from "react";
import Flex from "../../../../../../helpers/components/Flex";
import { useAdminStore } from "../../../../../../store/useStores";
import createTags from "./helpers/functions/createTags";
import createServerValue from "./helpers/functions/createServerValue";
import useFunctionTags from "./hooks/useFunctionTags";
import TagOperations from "./tagOperations/TagOperations";
import TagDisplay from "./tagDisplay/TagDisplay";
import InfoPanel from "./infoPanel/InfoPanel";

const FormulaConfiguration = ({
  data,
  changes,
  addChange,
  config,
  defaultFieldTags,
  currentModuleID,
}) => {
  const [tags, setTags] = useState([]);
  //const currentModuleID = useAdminStore((state) => state.moduleID);
  const [showInputMessage, setShowInputMessage] = useState(false);

  const [fieldTags, setFieldTags] = useState({});
  const [editFieldTagInfo, setEditFieldTagInfo] = useState({
    moduleID: currentModuleID,
    fieldID: null,
    index: -1,
  });

  const [editFunctionTagInfo, setEditFunctionTagInfo] = useState({
    functionID: null,
    index: -1,
  });

  const [selectFieldModalVisible, setSelectFieldModalVisible] = useState(false);
  const [functionModalVisible, setFunctionModalVisible] = useState(false);

  const functionTags = useFunctionTags();

  useEffect(() => {
    const newTags = createTags(data?.F_CALC);
    setFieldTags(defaultFieldTags);
    setTags(newTags);
  }, []);

  useEffect(() => {
    let precision = "0";
    if (changes?.DECIMAL) {
      precision = changes?.DECIMAL;
    } else if (data?.DECIMAL) {
      precision = data?.DECIMAL;
    }
    const serverValue = createServerValue(tags, precision);

    addChange("F_CALC", serverValue);
  }, [tags, changes?.DECIMAL]);

  const showFieldModal = () => setSelectFieldModalVisible(true);

  const cancelFieldModal = () => {
    setSelectFieldModalVisible(false);
    setEditFieldTagInfo({
      moduleID: currentModuleID,
      fieldID: null,
      index: -1,
    });
  };

  const showFunctionModal = () => setFunctionModalVisible(true);

  const cancelFunctionModal = () => {
    setFunctionModalVisible(false);
    setEditFunctionTagInfo({
      functionID: null,
      index: -1,
    });
  };

  const updateEditFieldTagInfo = (info) => {
    setEditFieldTagInfo(info);
  };

  const updateEditFunctionTagInfo = (info) => {
    setEditFunctionTagInfo(info);
  };

  const updateTags = (value) => {
    setTags(value);
  };

  const updateFieldTags = (value) => {
    setFieldTags(value);
  };

  const updateShowInputMessage = (value) => {
    setShowInputMessage(value);
  };

  return (
    <Flex
      key={tags.length}
      flexDirection={"column"}
      style={{
        gap: 15,
        marginTop: 0,
        marginLeft: 20,
        marginBottom: 10,
        width: "100%",
      }}
    >
      <Flex flexDirection={"row"} style={{ gap: 15 }}>
        <TagDisplay
          tags={tags}
          updateTags={updateTags}
          functionTags={functionTags}
          updateEditFunctionTagInfo={updateEditFunctionTagInfo}
          fieldTags={fieldTags}
          updateEditFieldTagInfo={updateEditFieldTagInfo}
          updateShowInputMessage={updateShowInputMessage}
          showFieldModal={showFieldModal}
          showFunctionModal={showFunctionModal}
        />
        <InfoPanel tags={tags} />
      </Flex>
      <TagOperations
        tags={tags}
        updateTags={updateTags}
        showInputMessage={showInputMessage}
        updateShowInputMessage={updateShowInputMessage}
        editFieldTagInfo={editFieldTagInfo}
        updateEditFieldTagInfo={updateEditFieldTagInfo}
        editFunctionTagInfo={editFunctionTagInfo}
        fieldTags={fieldTags}
        updateFieldTags={updateFieldTags}
        selectFieldModalVisible={selectFieldModalVisible}
        showFieldModal={showFieldModal}
        cancelFieldModal={cancelFieldModal}
        functionModalVisible={functionModalVisible}
        showFunctionModal={showFunctionModal}
        cancelFunctionModal={cancelFunctionModal}
        addChange={addChange}
        currentFieldID={data?.F_ID}
        currentModuleID={currentModuleID}
      />
    </Flex>
  );
};

export default FormulaConfiguration;
