import Flex from "../../../../../helpers/components/Flex";
import { Select } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import {
  useAuthStore,
  useCommonTitlesStore,
} from "../../../../../store/useStores";
import { useState, useEffect } from "react";
import apiGetFkValues from "../../../../../api/endpoints/apiGetFkValues";

const { Option } = Select;

const SingleSelectionType = ({
  value,
  id,
  config,
  required,
  addChange,
  canupdate,
  canupdateifnull,
  foreignKeyValues,
  row,
  autoCompletedFields = {},
  parentField,
  moduleID,
  changes,
}) => {
  const baseURL = useAuthStore((state) => state.baseURL);
  const userID = useAuthStore((state) => state.userID);
  const [options, setOptions] = useState([]);
  const [newValue, setNewValue] = useState(null);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  // let options = [];
  // if (foreignKeyValues[id]) {
  //   options = Object.entries(foreignKeyValues[id]).map(([key, value]) => ({
  //     id: key,
  //     view: value.view,
  //   }));
  //   options.sort((a, b) => a.view.localeCompare(b.view));
  // }

  useEffect(() => {
    if (config === "filters") {
      if (value) {
        setNewValue(value);
      } else {
        setNewValue([]);
      }
    } else if (
      Object.keys(autoCompletedFields).includes(id) &&
      autoCompletedFields?.[id]
    ) {
      setNewValue(autoCompletedFields?.[id]);
    } else {
      if (parentField && changes?.[parentField]) {
        setNewValue("");
        addChange(id, "", null);
      } else {
        if (value) {
          setNewValue(value);
        }
      }
    }
  }, []);
  //let newValue = value ?? "";

  // if (
  //   Object.keys(autoCompletedFields).includes(id) &&
  //   autoCompletedFields?.[id]
  // ) {
  //   newValue = autoCompletedFields[id];
  // }

  // if (config === "filters") {
  //   newValue = value ?? [];
  // }

  let isDisabled = false;
  if (config !== "filters") {
    if (Object.keys(autoCompletedFields).includes(id)) {
      isDisabled = true;
    } else {
      if (canupdate === false) {
        if (canupdateifnull === true) {
          if (newValue.length) {
            if (row[`${id}-US`] !== userID) {
              isDisabled = true;
            }
          }
        } else {
          isDisabled = true;
        }
      }
    }
  }

  useEffect(async () => {
    const fieldID = id;
    const parentFieldValue = changes?.[parentField]
      ? changes?.[parentField]
      : row?.[`${parentField}-server`];

    if (parentField && parentField !== "0" && parentFieldValue) {
      const fkValues = await apiGetFkValues(
        baseURL,
        moduleID,
        fieldID,
        parentField,
        parentFieldValue
      );

      //console.log("fkValues: ", fkValues);

      if (typeof fkValues === "object") {
        let tempOptions = Object.entries(fkValues).map(([key, value]) => ({
          id: key,
          view: value.view,
        }));
        tempOptions.sort((a, b) => a.view.localeCompare(b.view));

        setOptions(tempOptions);
      } else {
        let tempOptions = [];
        if (foreignKeyValues[id]) {
          tempOptions = Object.entries(foreignKeyValues[id]).map(
            ([key, value]) => ({
              id: key,
              view: value.view,
            })
          );
          tempOptions.sort((a, b) => a.view.localeCompare(b.view));
        }
        setOptions(tempOptions);
      }
    } else {
      let tempOptions = [];
      if (foreignKeyValues[id]) {
        tempOptions = Object.entries(foreignKeyValues[id]).map(
          ([key, value]) => ({
            id: key,
            view: value.view,
          })
        );
        tempOptions.sort((a, b) => a.view.localeCompare(b.view));
      }
      setOptions(tempOptions);
    }
  }, []);

  useEffect(async () => {
    const fieldID = id;
    const parentFieldValue = changes?.[parentField]
      ? changes?.[parentField]
      : row?.[`${parentField}-server`];

    if (parentField && parentField !== "0" && parentFieldValue) {
      const fkValues = await apiGetFkValues(
        baseURL,
        moduleID,
        fieldID,
        parentField,
        parentFieldValue
      );

      //console.log("fkValues: ", fkValues);

      if (typeof fkValues === "object") {
        let tempOptions = Object.entries(fkValues).map(([key, value]) => ({
          id: key,
          view: value.view,
        }));
        tempOptions.sort((a, b) => a.view.localeCompare(b.view));

        setOptions(tempOptions);
      } else {
        let tempOptions = [];
        if (foreignKeyValues[id]) {
          tempOptions = Object.entries(foreignKeyValues[id]).map(
            ([key, value]) => ({
              id: key,
              view: value.view,
            })
          );
          tempOptions.sort((a, b) => a.view.localeCompare(b.view));
        }
        setOptions(tempOptions);
      }
    } else {
      let tempOptions = [];
      if (foreignKeyValues[id]) {
        tempOptions = Object.entries(foreignKeyValues[id]).map(
          ([key, value]) => ({
            id: key,
            view: value.view,
          })
        );
        tempOptions.sort((a, b) => a.view.localeCompare(b.view));
      }
      setOptions(tempOptions);
    }
  }, [changes?.[parentField]]);

  return (
    <Flex key={newValue} flexDirection={"row"}>
      <div className="select">
        <Select
          mode={config === "filters" && "multiple"}
          allowClear
          style={{
            width: 278,
            marginLeft: 7,
            marginTop: 4,
          }}
          showSearch={true}
          filterOption={(iv, op) => {
            const replacements = [
              { key: "ί", newKey: "ι" },
              { key: "έ", newKey: "ε" },
              { key: "ό", newKey: "ο" },
              { key: "ή", newKey: "η" },
              { key: "ύ", newKey: "υ" },
              { key: "ώ", newKey: "ω" },
              { key: "ά", newKey: "α" },
              { key: "ς", newKey: "σ" },
            ];
            let opToUse = op.children ? op.children.toLowerCase() : "";
            let ivToUse = iv.toLowerCase();
            replacements.forEach(({ key, newKey }) => {
              var re = new RegExp(key, "g");
              ivToUse = ivToUse.replace(re, newKey);
              opToUse = opToUse.replace(re, newKey);
            });
            return opToUse.includes(ivToUse);
          }}
          onChange={(e) => {
            addChange(id, e, null);
            setNewValue(e);
          }}
          value={newValue}
          disabled={isDisabled}
          dropdownStyle={{ overflowY: "auto" }}
          virtual={false}
        >
          {config !== "filters" && <Option value={""} />}
          {config === "filters" && (
            <Option value={"NV"} className="option">
              {commonTitles?.["CO|1|NOVALUE"]}
            </Option>
          )}
          {options.map((item) => (
            <Option key={item?.view} className="option" value={item?.id}>
              {item?.view}
            </Option>
          ))}
        </Select>
      </div>
      {required === "Y" && (config === "copy" || config === "update") && (
        <p style={{ color: "grey", marginTop: 8, marginLeft: 15 }}>
          <WarningOutlined />
        </p>
      )}
    </Flex>
  );
};

export default SingleSelectionType;
