import moment from "moment";

const withGroupDateYearly = (
  columns,
  data,
  xAxisField,
  dateFormatToUse,
  commonTitles,
  groupField,
  foreignKeyValues,
  staticListColumns,
  dependentColumns,
  countColumns,
  numberAlertColumns,
  multipleValuesColumns,
  xValuesUsed,
  yValuesUsed,
  noDateDataColumnStatus
) => {
  let childxAxisField;
  let xAxisFieldToUse = xAxisField;

  if (xAxisField?.includes("-")) {
    let tempXAxisField = xAxisField.split(" - ");

    xAxisFieldToUse = tempXAxisField[0];
    childxAxisField = tempXAxisField[1];
  }

  const xAxisFieldColumn = columns.filter(
    (column) => column.F_ID === xAxisFieldToUse
  )[0];

  let xAxisFieldName;
  let childxAxisFieldIndex;
  let childxAxisNumber;

  if (childxAxisField) {
    const childTitle = xAxisFieldColumn.DEP_FIELDS?.filter(
      (field) => field.F_ID === childxAxisField
    ).map((item) => item.TITLE);
    xAxisFieldName = `${xAxisFieldColumn.T_TITLE} - ${childTitle}`;
    childxAxisNumber = xAxisFieldColumn.DEP_FIELDS?.length;
    const dependentFields = columns?.filter(
      (column) => column.F_ID === xAxisFieldToUse
    )[0].DEP_FIELDS;

    childxAxisFieldIndex = dependentFields.findIndex((object) => {
      return object.F_ID === childxAxisField;
    });
  } else {
    xAxisFieldName = xAxisFieldColumn.T_TITLE;
  }

  const isAlert =
    columns.filter((column) => column?.F_ID === xAxisFieldToUse)?.[0]
      ?.F_RENDER === "ALERT"
      ? true
      : false;

  const isDependent =
    columns.filter((column) => column?.F_ID === xAxisFieldToUse)?.[0]
      ?.F_TYPE === "30"
      ? true
      : false;

  let uniqueYears = [];

  let years = data.map((item) => {
    if (dependentColumns.includes(xAxisFieldToUse)) {
      if (item[xAxisFieldToUse] && item[xAxisFieldToUse].includes(",")) {
        const tempValue = item[xAxisFieldToUse].split(", ");
        const finalValue = tempValue[childxAxisFieldIndex];
        return moment(finalValue, dateFormatToUse).toDate().getFullYear();
      } else {
        return item[xAxisFieldToUse] ?? null;
      }
    } else {
      if (isAlert) {
        if (item[xAxisFieldToUse]?.value) {
          return moment(item[xAxisFieldToUse]?.value, dateFormatToUse)
            .toDate()
            .getFullYear();
        } else return null;
      } else {
        if (item[xAxisFieldToUse]) {
          return moment(item[xAxisFieldToUse], dateFormatToUse)
            .toDate()
            .getFullYear();
        } else return null;
      }
    }
  });

  years.forEach((item) => {
    if (item && !uniqueYears.includes(item)) {
      uniqueYears.push(item);
    }
  });

  if (!noDateDataColumnStatus) {
    uniqueYears.push(commonTitles["CO|1|NODATEDATA"]);
  }

  uniqueYears.sort((a, b) => {
    return a - b;
  });

  //console.log("uniqueYears: ", uniqueYears);

  let childGroupField;
  let groupFieldToUse = groupField;

  if (groupField?.includes("-")) {
    let tempGroupField = groupField.split(" - ");

    groupFieldToUse = tempGroupField[0];
    childGroupField = tempGroupField[1];
  }

  const groupFieldColumn = columns.filter(
    (column) => column.F_ID === groupFieldToUse
  )[0];

  let groupFieldName;
  let childGroupFieldIndex;
  let childGroupNumber;
  if (childGroupField) {
    const childTitle = groupFieldColumn?.DEP_FIELDS.filter(
      (field) => field.F_ID === childGroupField
    ).map((item) => item.TITLE);
    groupFieldName = `${groupFieldColumn?.T_TITLE} - ${childTitle}`;
    childGroupNumber = groupFieldColumn?.DEP_FIELDS.length;
    const dependentFields = columns.filter(
      (column) => column.F_ID === groupFieldToUse
    )[0]?.DEP_FIELDS;

    childGroupFieldIndex = dependentFields.findIndex((object) => {
      return object.F_ID === childGroupField;
    });
  } else {
    groupFieldName = groupFieldColumn?.T_TITLE;
  }

  let groupFieldValues = data.map((item) => {
    if (multipleValuesColumns.includes(groupFieldToUse)) {
      return item[`${groupFieldToUse}-server`];
    } else if (dependentColumns.includes(groupFieldToUse)) {
      if (item[groupFieldToUse] && item[groupFieldToUse].includes(",")) {
        const tempValue = item[groupFieldToUse].split(", ");
        const finalValue = tempValue[childGroupFieldIndex];
        return finalValue;
      } else {
        return item[groupFieldToUse];
      }
    } else if (numberAlertColumns.includes(groupFieldToUse)) {
      return item[groupFieldToUse]?.value;
    } else return item[groupFieldToUse];
  });

  console.log("groupFieldValues: ", groupFieldValues);

  let uniqueGroupFieldValues = [];
  groupFieldValues.forEach((item) => {
    if (
      item &&
      staticListColumns.includes(groupFieldToUse) &&
      typeof item === "object"
    ) {
      item = item?.view;
    }
    if (
      item &&
      item.includes(",") &&
      !countColumns.includes(groupFieldToUse) &&
      !staticListColumns.includes(groupFieldToUse)
    ) {
      const myArray = item.split(",");
      myArray.forEach((value) => {
        if (value && !uniqueGroupFieldValues.includes(value)) {
          uniqueGroupFieldValues.push(value);
        }
      });
    } else {
      if (item && !uniqueGroupFieldValues.includes(item)) {
        uniqueGroupFieldValues.push(item);
      }
    }
  });

  //console.log("uniqueGroupFieldValues: ", uniqueGroupFieldValues);

  if (multipleValuesColumns.includes(groupFieldToUse)) {
    uniqueGroupFieldValues = uniqueGroupFieldValues.map((value) => {
      if (foreignKeyValues[groupFieldToUse][value]?.view.includes(",")) {
        const myArray =
          foreignKeyValues[groupFieldToUse][value]?.view.split(", ");
        return myArray[0];
      } else {
        return foreignKeyValues[groupFieldToUse][value]?.view;
      }
    });
  }

  if (!yValuesUsed) {
    uniqueGroupFieldValues.push(
      `${commonTitles["CO|1|NODATAFOR"]} ${groupFieldName}`
    );
  }

  const isGroupFieldAlert =
    columns.filter((column) => column?.F_ID === groupFieldToUse)?.[0]
      ?.F_RENDER === "ALERT"
      ? true
      : false;

  let yearlyCount = {};
  for (let i = 0; i < uniqueYears.length; i++) {
    for (let j = 0; j < uniqueGroupFieldValues.length; j++) {
      let counter = 0;
      let pseudoCounter = 0;
      //let noGroupFieldDataForYear = true;
      for (let z = 0; z < data.length; z++) {
        let dateValue = isAlert
          ? data[z][xAxisFieldToUse]?.value
          : data[z][xAxisFieldToUse];

        let year;

        if (dateValue) {
          year = moment(dateValue, dateFormatToUse).toDate().getFullYear();
        } else {
          year = commonTitles["CO|1|NODATEDATA"];
        }

        if (
          year === uniqueYears[i] ||
          (isDependent && dateValue.includes(uniqueYears[i]))
        ) {
          let groupFieldValue = isGroupFieldAlert
            ? data[z][groupFieldToUse]?.value
            : data[z][groupFieldToUse];

          if (!groupFieldValue) {
            pseudoCounter += 1;
          }
          if (
            groupFieldValue &&
            staticListColumns.includes(groupFieldToUse) &&
            typeof groupFieldValue === "object"
          ) {
            groupFieldValue = groupFieldValue.view;
          }
          if (groupFieldValue && dependentColumns.includes(groupFieldToUse)) {
            if (groupFieldValue.includes(",")) {
              let tempArray = groupFieldValue.split(", ");
              //groupFieldValue = tempArray[childGroupFieldIndex];
              if (
                tempArray[childGroupFieldIndex] === uniqueGroupFieldValues[j]
              ) {
                //noGroupFieldDataForYear = false;
                counter += 1;
              }
            } else {
              if (groupFieldValue === uniqueGroupFieldValues[j]) {
                //noGroupFieldDataForYear = false;
                counter += 1;
              }
            }
          } else {
            if (groupFieldValue) {
              if (
                groupFieldValue === uniqueGroupFieldValues[j] ||
                groupFieldValue.includes(uniqueGroupFieldValues[j])
              ) {
                //noGroupFieldDataForYear = false;
                counter += 1;
              }
            }
          }
        }

        yearlyCount = {
          ...yearlyCount,
          [uniqueYears[i]]: {
            ...yearlyCount[uniqueYears[i]],
            [uniqueGroupFieldValues[j]]:
              uniqueGroupFieldValues[j] ===
              `${commonTitles["CO|1|NODATAFOR"]} ${groupFieldName}`
                ? pseudoCounter
                : counter,
          },
        };
      }
    }
  }

  let totalSum = 0;

  Object.entries(yearlyCount).forEach(([key, value]) => {
    let sum = 0;

    Object.values(value).forEach((item) => {
      sum += item;
      totalSum += item;
    });
    if (sum || !xValuesUsed) {
      yearlyCount[key] = { ...yearlyCount[key], sum: sum };
    } else {
      if (xValuesUsed) {
        delete yearlyCount[key];
      }
    }
  });

  return {
    dailyCount: {},
    monthlyCount: {},
    yearlyCount: { ...yearlyCount, totalSum: totalSum },
  };
};

export default withGroupDateYearly;
