import { ResponsiveLine } from "@nivo/line";
import {
  useChartStore,
  useCommonTitlesStore,
} from "../../../../store/useStores";
import createLabels from "../../UC helpers/createLabels";
import Flex from "../../../../helpers/components/Flex";

const Line = ({ data, max, total, columns, staticOptions }) => {
  const color = useChartStore((state) => state.color);
  const interpolation = useChartStore((state) => state.interpolation);
  const lineGroupMode = useChartStore((state) => state.lineGroupMode);
  const displayFunction = useChartStore((state) => state.displayFunction);
  const grouping = useChartStore((state) => state.grouping);
  const dateField = useChartStore((state) => state.dateField);
  const groupField = useChartStore((state) => state.groupField);
  const sumField = useChartStore((state) => state.sumField);
  const colorSet20 = useChartStore((state) => state.colorSet20);
  const colorSet40 = useChartStore((state) => state.colorSet40);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  //console.log("LineChart: data: ", data);

  let childDateField;
  let dateFieldToUse = dateField;

  if (dateField?.includes("-")) {
    let tempDateField = dateField.split(" - ");

    dateFieldToUse = tempDateField[0];
    childDateField = tempDateField[1];
  }

  const dateFieldColumn = columns?.filter(
    (column) => column.F_ID === dateFieldToUse
  )[0];

  let dateFieldName;
  let dateFieldType;
  let childDateFieldIndex;
  let childDateNumber;
  if (childDateField) {
    const childTitle = dateFieldColumn?.DEP_FIELDS?.filter(
      (field) => field.F_ID === childDateField
    ).map((item) => item.TITLE);
    const childType = dateFieldColumn?.DEP_FIELDS?.filter(
      (field) => field.F_ID === childDateField
    ).map((item) => item.F_TYPE);
    dateFieldName = `${dateFieldColumn?.T_TITLE} - ${childTitle}`;
    dateFieldType = childType;
    childDateNumber = dateFieldColumn?.DEP_FIELDS?.length;
    const dependentFields = columns?.filter(
      (column) => column.F_ID === dateFieldToUse
    )[0]?.DEP_FIELDS;

    childDateFieldIndex = dependentFields.findIndex((object) => {
      return object.F_ID === childDateField;
    });
  } else {
    dateFieldName = dateFieldColumn?.T_TITLE;
    dateFieldType = dateFieldColumn?.F_TYPE;
  }

  let childGroupField;
  let groupFieldToUse = groupField;

  if (groupField?.includes("-")) {
    let tempGroupField = groupField.split(" - ");

    groupFieldToUse = tempGroupField[0];
    childGroupField = tempGroupField[1];
  }

  const groupFieldColumn = columns?.filter(
    (column) => column.F_ID === groupFieldToUse
  )[0];

  let groupFieldName;
  let groupFieldType;
  let childGroupFieldIndex;
  let childGroupNumber;
  if (childGroupField) {
    const childTitle = groupFieldColumn?.DEP_FIELDS?.filter(
      (field) => field.F_ID === childGroupField
    ).map((item) => item.TITLE);
    const childType = groupFieldColumn?.DEP_FIELDS?.filter(
      (field) => field.F_ID === childGroupField
    ).map((item) => item.F_TYPE);
    groupFieldName = `${groupFieldColumn?.T_TITLE} - ${childTitle}`;
    groupFieldType = childType;
    childGroupNumber = groupFieldColumn?.DEP_FIELDS?.length;
    const dependentFields = columns?.filter(
      (column) => column.F_ID === groupFieldToUse
    )[0]?.DEP_FIELDS;

    childGroupFieldIndex = dependentFields.findIndex((object) => {
      return object.F_ID === childGroupField;
    });
  } else {
    groupFieldName = groupFieldColumn?.T_TITLE;
    groupFieldType = groupFieldColumn?.F_TYPE;
  }

  const { XLabel, YLabel } = createLabels(
    grouping,
    displayFunction,
    commonTitles,
    dateField,
    columns
  );

  const numOfItems = data.length;

  let dataForLegends = data.map((item) => item.id).sort();
  //console.log("dataForLegends: ", dataForLegends);

  let chartColor = colorSet20;
  if (numOfItems > 20) {
    chartColor = [...colorSet20, ...colorSet40];
  }

  //console.log("dataForLegends: ", dataForLegends);

  let dataForLegendsPart1 = [];
  let dataForLegendsPart2 = [];
  if (numOfItems <= 20) {
    dataForLegendsPart1.push(...dataForLegends);
  } else {
    for (let i = 0; i < dataForLegends.length; i++) {
      if (i <= numOfItems / 2 - 1) {
        dataForLegendsPart1.push(dataForLegends[i]);
      } else {
        dataForLegendsPart2.push(dataForLegends[i]);
      }
    }
    // dataForLegendsPart2.push(
    //   `${commonTitles["CO|1|NODATAFOR"]} ${groupFieldName}`
    // );
  }

  //console.log("dataForLegendsPart1: ", dataForLegendsPart1);
  // console.log("dataForLegendsPart2: ", dataForLegendsPart2);

  if (groupFieldType === "6") {
    let options = Object.values(staticOptions?.[groupField]);
    options.sort((a, b) => a.ORDER - b.ORDER);

    dataForLegends = options.map((option) => option.TITLE);
    if (dataForLegends.length > 20) {
      dataForLegendsPart1 = dataForLegends.slice(
        0,
        dataForLegends.length / 2 - 1
      );
      dataForLegendsPart2 = dataForLegends.slice(
        dataForLegends.length / 2 - 1,
        dataForLegends.length
      );
      dataForLegendsPart2.push(
        `${commonTitles["CO|1|NODATAFOR"]} ${groupFieldName}`
      );
    } else {
      dataForLegendsPart1 = dataForLegends;
      dataForLegendsPart1.push(
        `${commonTitles["CO|1|NODATAFOR"]} ${groupFieldName}`
      );
    }
  }

  let colorKeys = data.map((item) => item.id);

  if (groupFieldType === "6") {
    colorKeys = Object.values(staticOptions?.[groupField]).map(
      (item) => item.TITLE
    );
  }

  let i = 0;
  let lineColors = {};

  colorKeys.forEach((key) => {
    if (groupFieldType === "6") {
      const color = Object.values(staticOptions?.[groupField]).filter(
        (item) => item?.TITLE === key
      )?.[0]?.BC;

      if (!["#ffffff", "rgb(255, 255, 255)"].includes(color) && color) {
        lineColors = { ...lineColors, [key]: color };
      } else {
        lineColors = { ...lineColors, [key]: chartColor[i] };
        i++;
      }
    } else if (!groupFieldType && dateFieldType === "6") {
      const color = Object.values(staticOptions?.[dateField]).filter(
        (item) => item?.TITLE === key
      )?.[0]?.BC;

      if (!["#ffffff", "rgb(255, 255, 255)"].includes(color) && color) {
        lineColors = { ...lineColors, [key]: color };
      } else {
        lineColors = { ...lineColors, [key]: chartColor[i] };
        i++;
      }
    } else {
      lineColors = { ...lineColors, [key]: chartColor[i] };
      i++;
    }
  });

  lineColors = {
    ...lineColors,
    [`${commonTitles["CO|1|NODATAFOR"]} ${groupFieldName}`]: "black",
  };

  //console.log("lineColors: ", lineColors);

  const getColor = (line) => {
    return lineColors?.[line?.id];
  };

  let showLegends = true;

  if (!groupField) {
    showLegends = false;
  }

  if (["sum", "pctSum"].includes(displayFunction) && !sumField) {
    showLegends = false;
  }

  return (
    <>
      <ResponsiveLine
        data={data}
        margin={{ top: 35, right: 310, bottom: 200, left: 60 }}
        enableSlices="x"
        sliceTooltip={({ slice }) => {
          return (
            <div
              style={{
                background: "white",
                padding: "9px 12px",
                border: "1px solid #ccc",
              }}
            >
              <div
                style={{
                  marginBottom: 5,
                  fontWeight: 500,
                  fontSize: numOfItems > 15 && 9,
                }}
              >
                {XLabel}: {slice.points[0].data.xFormatted}
              </div>
              {slice.points.map((point) => (
                <div
                  key={point.id}
                  style={{
                    color: point.serieColor,
                    padding: numOfItems < 15 ? "3px 0" : "1px 0",
                    fontSize: numOfItems > 15 && 9,
                  }}
                >
                  <strong>{`${point.serieId}: `}</strong>{" "}
                  {point.data.yFormatted}
                </div>
              ))}
            </div>
          );
        }}
        xScale={{ type: "point" }}
        //gridYValues={max}
        yFormat={
          ["pctCount", "pctSum"].includes(displayFunction) ? " >-.1%" : " >-.0f"
        }
        yScale={{
          type: "linear",
          min: 0,
          max: max,
          stacked: !lineGroupMode,
          reverse: false,
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: "bottom",
          tickSize: 5,
          tickPadding: 20,
          tickRotation: -90,
          legend: XLabel,
          legendOffset: 100,
          legendPosition: "middle",
        }}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickValues: total,
          tickPadding: 5,
          tickRotation: 0,
          legend: YLabel,
          legendOffset: -40,
          legendPosition: "middle",
        }}
        //colors={numOfItems > 10 ? chartColorPalette : { scheme: color }}
        colors={getColor}
        curve={interpolation}
        pointSize={10}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        useMesh={true}
        // legends={[
        //   {
        //     anchor: "bottom-right",
        //     direction: "column",
        //     justify: false,
        //     translateX: 100,
        //     translateY: 0,
        //     itemsSpacing: 0,
        //     itemDirection: "left-to-right",
        //     itemWidth: 80,
        //     itemHeight: 20,
        //     itemOpacity: 0.75,
        //     symbolSize: 12,
        //     symbolShape: "circle",
        //     symbolBorderColor: "rgba(0, 0, 0, .5)",
        //     effects: [
        //       {
        //         on: "hover",
        //         style: {
        //           itemBackground: "rgba(0, 0, 0, .03)",
        //           itemOpacity: 1,
        //         },
        //       },
        //     ],
        //   },
        // ]}
      />
      <Flex
        flexDirection={"column"}
        style={{
          marginLeft: "-17rem",
          marginTop: "1rem",
          display: !showLegends && "none",
        }}
      >
        {dataForLegendsPart1.map((item, index) => (
          <Flex
            key={`${index}`}
            flexDirection={"row"}
            style={{
              maxHeight: 13,
              marginBottom: 2,
              marginTop: numOfItems < 15 ? 4 : 2,
            }}
          >
            <div
              style={{
                width: numOfItems < 15 ? 13 : 10,
                maxHeight: numOfItems < 15 ? 12 : 9,
                borderRadius: 15,
                background: lineColors[item],
              }}
            />
            <p
              style={{
                marginTop: numOfItems < 15 ? -3 : -2,
                marginLeft: 6,
                fontSize: numOfItems < 15 ? 11 : 9,
                width: "max-content",
              }}
            >
              {item}
            </p>
          </Flex>
        ))}
      </Flex>
      <Flex
        flexDirection={"column"}
        style={{
          marginLeft: "1rem",
          marginTop: "1rem",
          display: !showLegends && "none",
        }}
      >
        {dataForLegendsPart2.map((item, index) => (
          <Flex
            key={index}
            flexDirection={"row"}
            style={{
              maxHeight: 13,
              marginBottom: 2,
              marginTop: numOfItems < 15 ? 4 : 2,
            }}
          >
            <div
              style={{
                width: numOfItems < 15 ? 13 : 10,
                maxHeight: numOfItems < 15 ? 12 : 9,
                borderRadius: 15,
                background: lineColors[item],
              }}
            />
            <p
              style={{
                marginTop: numOfItems < 15 ? -3 : -2,
                marginLeft: 6,
                fontSize: numOfItems < 15 ? 11 : 9,
                width: "max-content",
              }}
            >
              {item}
            </p>
          </Flex>
        ))}
      </Flex>
    </>
  );
};

export default Line;
