import Flex from "../../../../../../../../helpers/components/Flex";
import { Button } from "antd";

const AddNumber = ({ tags, updateTags }) => {
  const numbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];

  const handleChange = (number) => {
    if (!tags.includes(number)) {
      if (tags.length) {
        if (
          !tags[tags.length - 1].includes("[") &&
          !tags[tags.length - 1].includes("#")
        ) {
          let newTags = [...tags];

          if (newTags[tags.length - 1] !== ",") {
            newTags[tags.length - 1] = tags[tags.length - 1] + number;
          } else {
            newTags.push(number);
          }

          updateTags(newTags);
        } else {
          updateTags([...tags, number]);
        }
      } else {
        updateTags([number]);
      }
    }
  };

  return (
    <Flex flexDirection={"row"} style={{ gap: 8 }}>
      {numbers.map((number) => (
        <Button
          key={number}
          style={{ width: 15, display: "flex", justifyContent: "center" }}
          onClick={() => handleChange(number)}
        >
          {number}
        </Button>
      ))}
    </Flex>
  );
};

export default AddNumber;
