const chooseTableCellStyle = (
  id,
  frozenColumns = [],
  bgColor,
  selection,
  columns,
  config = "data"
) => {
  let cellStyle = { backgroundColor: bgColor ?? "white" };

  if (config === "totals") {
    cellStyle = {
      backgroundColor: bgColor ?? "#E5E4E2",
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 3,
      paddingBottom: 0,
    };
  }

  if (frozenColumns?.length) {
    let idIndex = frozenColumns.indexOf(id);
    if (frozenColumns.includes(id)) {
      cellStyle = { ...cellStyle, position: "sticky", zIndex: 3 };

      let leftAddition = 0;

      let columnsToUse = frozenColumns.filter(
        (column, colIndex) => colIndex < idIndex
      );

      columns
        .filter((column) => columnsToUse.includes(column?.F_ID))
        .forEach((column) => {
          leftAddition += parseFloat(column?.F_WIDTH) ?? 0;
        });

      if (selection) {
        cellStyle = {
          ...cellStyle,
          left: 82.5 + leftAddition,
        };
      } else {
        cellStyle = {
          ...cellStyle,
          left: 34 + leftAddition,
        };
      }
    }

    // if (idIndex === frozenColumns.length - 1) {
    //   cellStyle = {
    //     ...cellStyle,
    //     borderRight: "4px solid #0a3648",
    //   };
    // }
  }

  return cellStyle;
};

export default chooseTableCellStyle;
