import { Button } from "antd";
import {
  useCommonTitlesStore,
  useAdminStore,
} from "../../../../../../../../store/useStores";
import { eraseButtonStyle } from "../../styles/TagItemStyles";

const EraseButton = ({ updateTags, updateEditFieldTagInfo, addChange }) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const currentModuleID = useAdminStore((state) => state.moduleID);

  const eraseAll = () => {
    updateTags([]);
    updateEditFieldTagInfo({
      moduleID: currentModuleID,
      fieldID: null,
      index: -1,
    });
    addChange("F_CALC", "");
  };
  return (
    <Button key="erase" style={eraseButtonStyle} onClick={() => eraseAll()}>
      <p style={{ fontSize: 12, marginBottom: 8 }}>
        {commonTitles["CO|1|CLEARALL"]}
      </p>
    </Button>
  );
};

export default EraseButton;
