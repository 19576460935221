import { useState } from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import "./css/ultimateTable.css";
import { Layout, Tooltip } from "antd";
import { useEffect } from "react";
import useSort from "./hooks/useSort";
import useInvert from "./hooks/useInvert";
import moment from "moment";
import { convertMonthToLabel } from "./helpers/converters";
import {
  useAuthStore,
  useChartStore,
  useCommonTitlesStore,
  useModuleStore,
  usePaginationStore,
} from "../../store/useStores";
import { convertDateFormat } from "./helpers/converters";
import TableSettings from "./UltimateTableSettings";
import TableHeader from "./UltimateTableHead";
import UltimateTableBody from "./UltimateTableBody";
import axios from "axios";
import displayResponseMessage from "../../helpers/functions/displayResponseMessage";
import createOptions from "../ultimateChart/UC helpers/createOptions";
import UltimateTablePagination from "./UltimateTablePagination";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";

const { Content } = Layout;

const UltimateTable = ({
  title = "",
  columns = [],
  data = [],
  filters = {},
  staticOptions = {},
  foreignKeyValues = {},
  privileges = {},
  updateData,
  deleteData,
  insertData,
  updateFilters,
  updateOrderConfig,
  moduleID,
  config,
  updateCurrentPage,
  updatePageSize,
  changeDisplayedColumns,
  totalRowWidth = 1,
  isProjectSpecific,
  existingRowIDs,
  autoCompletedFields = {},
  totals = {},
  updateTotals,
  showTotals,
  updateShowTotals,
  frozenColumns = [],
  updateFrozenColumns,
  kpis = [],
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const baseURL = useAuthStore((state) => state.baseURL);
  const role = useAuthStore((state) => state.role);
  const showGroup = useAuthStore((state) => state.showGroup);
  const setShowGroup = useAuthStore((state) => state.setShowGroup);
  const setDateFieldOptions = useChartStore(
    (state) => state.setDateFieldOptions
  );
  const setGroupFieldOptions = useChartStore(
    (state) => state.setGroupFieldOptions
  );

  const pageSize = usePaginationStore((state) => state.pageSize);
  const currentPage = usePaginationStore((state) => state.currentPage);

  const orderBy = useModuleStore((state) => state.orderBy);
  const orderDirection = useModuleStore((state) => state.orderDirection);
  const numberTypes = useModuleStore((state) => state.numberTypes);

  const dateFormat = useAuthStore((state) => state.dateFormat);
  let dateFormatToUse = convertDateFormat(dateFormat);

  const columnsWithDate = columns.filter(({ F_TYPE }) => F_TYPE === "4");
  // .map(({ F_ID }) => F_ID);

  let dataToUse = Object.entries(data).map(([key, value]) => {
    const dateColumns = {};
    columnsWithDate.forEach((column) => {
      dateColumns[`${column?.F_ID}-date`] = moment(
        column?.F_RENDER === "ALERT"
          ? value[column?.F_ID]?.value
          : value[column?.F_ID],
        dateFormatToUse
      ).toDate();
    });
    return { rowID: value.ROWID, ...value, ...dateColumns };
  });

  let allDataToUse = dataToUse;

  const { sortedItems, sort, decideSortIcon } = useSort(
    dataToUse,
    columns,
    updateOrderConfig,
    config,
    orderBy?.[moduleID],
    orderDirection?.[moduleID] === undefined
      ? "ASC"
      : orderDirection?.[moduleID]
  );

  if (pageSize?.[moduleID]) {
    dataToUse = sortedItems.filter(
      (item, index) =>
        index < currentPage * pageSize?.[moduleID] &&
        index >= (currentPage - 1) * pageSize?.[moduleID]
    );
  } else {
    dataToUse = sortedItems.filter(
      (item, index) =>
        index < currentPage * 20 && index >= (currentPage - 1) * 20
    );
  }
  // dataToUse = sortedItems.filter(
  //   (item, index) =>
  //     index < currentPage * pageSize?.[moduleID] &&
  //     index >= (currentPage - 1) * pageSize?.[moduleID]
  // );

  let grouping = [];

  const getColumnID = (group) => {
    if (config === "alerts") {
      if (group === "type") {
        let pattern = /type/;
        let result = group.match(pattern);
        return result[0];
      } else if (group === "module") {
        let pattern = /module/;
        let result = group.match(pattern);
        return result[0];
      } else {
        let pattern = /date/;
        let result = group.match(pattern);
        return result[0];
      }
    }
    let pattern = /\d{4}/;
    let result = group.match(pattern);
    return result[0];
  };

  if (showGroup) {
    const columnID =
      showGroup.length > 4 && showGroup !== "status"
        ? getColumnID(showGroup)
        : showGroup;
    const column = columns.find((column) => column.F_ID === columnID) ?? null;
    const isDate = column?.F_TYPE === "4" ?? false;
    const isCheckbox = column?.F_TYPE === "99" ?? false;
    const isNumberAlert =
      numberTypes.includes(column?.F_TYPE) && column?.F_RENDER === "ALERT"
        ? true
        : false;
    const isStaticList =
      (column?.F_TYPE === "6" && column?.F_RENDER !== "PROGRESS") ?? false;

    let valueToTest = dataToUse[0]?.[columnID];

    if (column?.F_RENDER === "ALERT") {
      valueToTest = dataToUse[0]?.[columnID]?.value;
    }

    const isPercentage =
      column?.F_RENDER === "PROGRESS" || isStaticList
        ? !!valueToTest?.view.match(/[%]/)
        : !!valueToTest?.match(/[%]/);

    if (
      showGroup.length > 4 &&
      !["status", "type", "module"].includes(showGroup)
    ) {
      allDataToUse.forEach((item) => {
        const date = item[`${columnID}-date`];

        const month = date.getMonth();
        const monthLabeled = convertMonthToLabel(month, commonTitles);

        const year = date.getFullYear();

        const label = `${monthLabeled} ${year}`;
        const exists = grouping.some((item) => item.label === label);

        if (!exists) {
          if (year) {
            grouping.push({
              label: label,
              dateInfo: { month: month, year: year },
              sortValue: new Date(year, month),
            });
          } else {
            let exists = grouping.some((item) => item.label === "-");
            if (!exists) {
              grouping.push({
                label: "-",
                dateInfo: { month: month, year: year },
                sortValue: new Date(year, month),
              });
            }
          }
        }
      });
    } else if (showGroup === "status") {
      allDataToUse.forEach((item) => {
        let label;
        if (item[showGroup].view === commonTitles["CO|1|EXPIRED"]) {
          label = commonTitles["CO|1|EXPIRED"];
        } else {
          label = commonTitles["CO|1|TOBEEXPRD"];
        }

        let sortValue = label;

        const exists = grouping.some((item) => item.label === label);
        if (!exists) {
          grouping.push({
            label: label,
            id: item[showGroup],
            sortValue: sortValue,
          });
        }
      });
    } else {
      allDataToUse.forEach((item) => {
        let label = item[showGroup] ?? "-";
        let sortValue = label;
        if (isNumberAlert) {
          label = item[showGroup]?.value ? item[showGroup]?.value : "-";
          sortValue = label;
        } else if (isDate) {
          if (column.F_RENDER === "ALERT") {
            label = item[showGroup]?.value ? item[showGroup]?.value : "-";
            sortValue = item?.[showGroup]?.value
              ? item[`${showGroup}-date`]
              : "-";
          } else {
            sortValue = item?.[showGroup] ? item[`${showGroup}-date`] : "-";
          }
        } else if (isPercentage) {
          label = sortValue !== "-" ? sortValue.view : "-";
          sortValue = sortValue !== "-" ? sortValue.view : null;

          sortValue = sortValue
            ? parseInt(sortValue.replace("%.*$", ""))
            : null;
        } else if (isStaticList) {
          label = label !== "-" ? label.view : "-";
          sortValue = label !== "-" ? label : null;
        } else if (isCheckbox) {
          label =
            item[showGroup] === "Y"
              ? `${column.T_TITLE}: ${commonTitles["CO|1|CBOXSEL"]}`
              : `${column.T_TITLE}: ${commonTitles["CO|1|CBOXNSEL"]}`;
          sortValue = item[showGroup] && item[showGroup] === "Y" ? "Y" : "N";
        }

        const exists = grouping.some((item) => item.label === label);
        if (!exists) {
          grouping.push({
            label: label,
            id: isCheckbox ? sortValue : label,
            //id: label,
            sortValue: sortValue,
          });
        }
      });
    }

    let comparator = null;
    if (isDate) {
      comparator = (a, b) => a.sortValue - b.sortValue;
    } else if (isPercentage) {
      comparator = (a, b) => a.sortValue - b.sortValue;
    } else if (isStaticList) {
      comparator = (a, b) => a.sortValue - b.sortValue;
    } else {
      comparator = (a, b) => a.sortValue.localeCompare(b.sortValue);
    }

    const noDataGroupingItem =
      grouping.filter((item) => item.label === "-") ?? null;

    grouping = grouping.filter((item) => item.label !== "-");
    grouping.sort(comparator);
    noDataGroupingItem && grouping.push(...noDataGroupingItem);
  }

  const changeGrouping = (key) => {
    if (showGroup === key) {
      setShowGroup("");
    } else {
      setShowGroup(key);
    }
  };

  let viewRights = {};
  columns.forEach((column) => {
    if (role === "A") {
      viewRights = { ...viewRights, [column.F_ID]: true };
    } else if (Object.keys(column).includes("canview")) {
      viewRights = {
        ...viewRights,
        [column.F_ID]: column.canview,
      };
    } else {
      viewRights = {
        ...viewRights,
        [column.F_ID]: privileges?.canView,
      };
    }
  });

  const [dense, setDense] = useState(false);
  const [selection, setSelection] = useState(false);

  const changeDense = () => {
    setDense(!dense);
  };

  const changeSelection = () => {
    setSelection(!selection);
  };

  const {
    items: selectedItems,
    isInverted: isSelected,
    invert: invertSelection,
    forceInvert: forceSelection,
  } = useInvert();

  const updateRow = (changes = {}, showAlert) => {
    updateData(changes, showAlert);
  };

  const deleteRow = async (rowIDs = [], mode, config, moduleID) => {
    var str = "";
    let changes = {};
    if (config === "alerts") {
      rowIDs.forEach((id) => {
        changes = Object.fromEntries(
          Object.entries(data).filter(
            ([key, value]) => !rowIDs.includes(value.rowID)
          )
        );

        if (str !== "") {
          str += ",";
        }
        str += id;
      });
    } else {
      rowIDs.forEach((id) => {
        changes = Object.fromEntries(
          Object.entries(data).filter(
            ([key, value]) => !rowIDs.includes(value.ROWID)
          )
        );

        if (str !== "") {
          str += ",";
        }
        str += id;
      });
    }

    // console.log("deleteRow: mode: ", mode);
    // console.log("deleteRow: config: ", config);
    // console.log("deleteRow: str: ", str);
    // console.log("deleteRow: rowIDs: ", rowIDs);
    // console.log("deleteRow: data: ", data);
    // console.log("deleteRow: changes: ", changes);
    // console.log("deleteRow: moduleID: ", moduleID);

    const url = `${baseURL}/Data_api/delete`;
    try {
      const response = await axios.post(
        url,
        { moduleid: moduleID, rowid: str },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Data_api/delete: response: ", response?.data);

      if (response?.data?.STATUS === "Success") {
        //message.success(commonTitles["CO|1|SUCDLTMSG1"], [3]);
        displayResponseMessage(commonTitles["CO|1|SUCDLTMSG1"], 3, "success");
        deleteData(changes);
        updateTotals(response?.data?.totals);
        if (mode === "multiple") {
          selectIndividualRow(rowIDs);
        }
      } else {
        const responseMsg = response?.data?.MESSAGE ?? {};
        let template = commonTitles[responseMsg.ID];

        Object.entries(responseMsg)
          .filter(([key]) => key !== "ID")
          .forEach(([key, value]) => {
            var re = new RegExp(key, "g");
            template = template.replace(re, value);
          });

        //message.warning(template, [3]);
        displayResponseMessage(template, 5, "warning");
      }
    } catch (err) {
      //message.error(err, [3]);
      displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
    }
  };

  const selectAll = () => {
    if (selectedItems.length !== allDataToUse.length) {
      const rowIDs = allDataToUse.map(({ rowID }) => rowID);
      forceSelection(rowIDs);
    } else forceSelection([]);
  };

  const selectIndividualRow = (id) => {
    invertSelection(id);
  };

  useEffect(() => {
    if (dataToUse.length) {
      const { tempDateFieldOptions, tempGroupFieldOptions } = createOptions(
        dataToUse,
        columns,
        foreignKeyValues,
        staticOptions,
        role,
        privileges,
        numberTypes
      );

      setDateFieldOptions(tempDateFieldOptions);
      setGroupFieldOptions(tempGroupFieldOptions);
    }
  }, [dataToUse]);

  return (
    <Layout
      key={showTotals}
      style={{
        borderRadius: 10,
        overflow: "hidden",
        height: "max-content",
        width: "100%",
      }}
    >
      <TableSettings
        key={currentPage}
        columns={columns}
        staticOptions={staticOptions}
        foreignKeyValues={foreignKeyValues}
        config={config}
        grouping={grouping}
        showGroup={showGroup}
        dense={dense}
        changeDense={changeDense}
        selectedItems={selectedItems}
        selection={selection}
        changeSelection={changeSelection}
        deleteRow={deleteRow}
        changeDisplayedColumns={changeDisplayedColumns}
        changeGrouping={changeGrouping}
        forceSelection={forceSelection}
        filters={filters}
        updateFilters={updateFilters}
        dataToUse={dataToUse}
        privileges={privileges}
        moduleID={moduleID}
        isProjectSpecific={isProjectSpecific}
        insertData={insertData}
        existingRowIDs={existingRowIDs}
        autoCompletedFields={autoCompletedFields}
        title={title}
        frozenColumns={frozenColumns}
        updateFrozenColumns={updateFrozenColumns}
        kpis={kpis}
      />
      <Layout className="site-layout">
        <Content
          style={{
            padding: 10,
            minHeight: "max-content",
            backgroundColor: "transparent",
            // overflow: "visible",
          }}
        >
          {showTotals && Object.keys(totals).length ? (
            <Tooltip placement="right" title={commonTitles["CO|1|HIDETOT"]}>
              <CaretDownOutlined onClick={() => updateShowTotals(false)} />
            </Tooltip>
          ) : Object.keys(totals).length && !showTotals ? (
            <Tooltip placement="right" title={commonTitles["CO|1|SHOWTOT"]}>
              <CaretUpOutlined onClick={() => updateShowTotals(true)} />
            </Tooltip>
          ) : null}
          <TableContainer
            component={Paper}
            className="testing_table"
            style={{
              maxHeight: "64vh",
              height: dataToUse?.length > 8 && "64vh",
              //overflowX: "hidden",
            }}
          >
            <Table
              className="table"
              stickyHeader
              size={dense ? "small" : "medium"}
            >
              <TableHeader
                columns={columns}
                dataToUse={dataToUse}
                selection={selection}
                selectedItems={selectedItems}
                selectAll={selectAll}
                sort={sort}
                decideSortIcon={decideSortIcon}
                config={config}
                privileges={privileges}
                totals={totals}
                totalRowWidth={totalRowWidth}
                showTotals={showTotals}
                moduleID={moduleID}
                frozenColumns={frozenColumns}
                allDataToUse={allDataToUse}
              />
              <UltimateTableBody
                key={frozenColumns}
                title={title}
                columns={columns}
                staticOptions={staticOptions}
                foreignKeyValues={foreignKeyValues}
                privileges={privileges}
                config={config}
                dataToUse={dataToUse}
                originalData={data}
                grouping={grouping}
                selection={selection}
                isSelected={isSelected}
                showGroup={showGroup}
                selectIndividualRow={selectIndividualRow}
                updateRow={updateRow}
                deleteRow={deleteRow}
                getColumnID={getColumnID}
                totalRowWidth={totalRowWidth}
                dateFormatToUse={dateFormatToUse}
                moduleID={moduleID}
                insertData={insertData}
                autoCompletedFields={autoCompletedFields}
                updateTotals={updateTotals}
                allDataToUse={allDataToUse}
                totals={totals}
                showTotals={showTotals}
                dense={dense}
                frozenColumns={frozenColumns}
              />
            </Table>
          </TableContainer>
          {config !== "info" && (
            <UltimateTablePagination
              total={Object.values(data)?.length}
              updateCurrentPage={updateCurrentPage}
              updatePageSize={updatePageSize}
              moduleID={moduleID}
              showGroup={showGroup}
            />
          )}
        </Content>
      </Layout>
    </Layout>
  );
};

export default UltimateTable;
