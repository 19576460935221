const chooseHeaderCellStyle = (
  width,
  id,
  frozenColumns,
  columns,
  selection
) => {
  let headerCellStyle = {
    width: width,
    backgroundColor: "white",
    paddinLeft: 0,
    paddingRight: 0,
  };

  if (!frozenColumns?.length) {
    if (id === "firstBox") {
      headerCellStyle = { ...headerCellStyle, position: "sticky", zIndex: 5 };
      if (selection) {
        headerCellStyle = {
          ...headerCellStyle,
          left: 48,
        };
      } else {
        headerCellStyle = {
          ...headerCellStyle,
          left: 0,
        };
      }
    }
  }

  if (frozenColumns?.length) {
    let idIndex = frozenColumns.indexOf(id);
    if (id === "firstBox") {
      headerCellStyle = { ...headerCellStyle, position: "sticky", zIndex: 5 };
      if (selection) {
        headerCellStyle = {
          ...headerCellStyle,
          left: 48,
        };
      } else {
        headerCellStyle = {
          ...headerCellStyle,
          left: 0,
        };
      }
    } else if (frozenColumns.includes(id)) {
      headerCellStyle = { ...headerCellStyle, position: "sticky", zIndex: 5 };
      let leftAddition = 0;

      let columnsToUse = frozenColumns.filter(
        (column, colIndex) => colIndex < idIndex
      );

      columns
        .filter((column) => columnsToUse.includes(column?.F_ID))
        .forEach((column) => {
          leftAddition += column?.width ?? 0;
        });

      if (selection) {
        headerCellStyle = {
          ...headerCellStyle,
          left: 82.5 + leftAddition,
        };
      } else {
        headerCellStyle = {
          ...headerCellStyle,
          left: 34.5 + leftAddition,
        };
      }
    }
  }

  // console.log("id: ", id);
  // console.log("headerCellStyle: ", headerCellStyle);

  return headerCellStyle;
};

export default chooseHeaderCellStyle;
