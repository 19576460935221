import Flex from "../../../../../helpers/components/Flex";
import { Tooltip } from "antd";
import htmlCodesRemover from "../../htmlCodesRemover";

const MultipleSelectionType = ({
  value,
  serverValue,
  foreignKeyValues,
  id,
  infoFields = [],
}) => {
  if (serverValue) {
    const tempServerValue = serverValue.split(",").filter((item) => item);
    let title = tempServerValue?.map((item) => (
      <Flex
        key={item}
        direction={"horizontal"}
        style={{ width: "max-content" }}
      >
        {tempServerValue?.length > 1 && <p style={{ marginRight: 5 }}>{"•"}</p>}
        <p>
          {foreignKeyValues?.[id]?.[item]?.view}
          <br />
        </p>
      </Flex>
    ));

    if (infoFields?.length === 1 && infoFields?.[0]?.F_TYPE === "33") {
      title = tempServerValue?.map((item) => (
        <Flex key={item} direction={"horizontal"}>
          {tempServerValue?.length > 1 && (
            <p style={{ marginRight: 5 }}>{"•"}</p>
          )}
          <a href={`mailto:${value}`}>{value}</a>
          <br />
        </Flex>
      ));
    }

    let newValue;

    if (infoFields?.length === 1 && infoFields?.[0]?.F_TYPE === "33") {
      newValue = tempServerValue?.map((item) => (
        <Flex key={item} direction={"horizontal"}>
          {tempServerValue?.length > 1 && (
            <p style={{ marginRight: 5 }}>{"•"}</p>
          )}
          <p>
            <a href={`mailto:${value}`}>{value}</a>
            <br />
          </p>
        </Flex>
      ));
    } else {
      newValue = tempServerValue?.map((item) => (
        <Flex key={item} direction={"horizontal"}>
          {tempServerValue?.length > 1 && (
            <p style={{ marginRight: 5 }}>{"•"}</p>
          )}
          <p>
            {foreignKeyValues?.[id]?.[item]?.view.length > 30
              ? foreignKeyValues?.[id]?.[item]?.view.slice(0, 30) + "..."
              : foreignKeyValues?.[id]?.[item]?.view}
            <br />
          </p>
        </Flex>
      ));
    }

    if (tempServerValue.length > 2) {
      const shortNewValue = tempServerValue
        ?.filter((item, index) => index < 2)
        ?.map((item) => (
          <Flex key={item} direction={"horizontal"}>
            {tempServerValue?.length > 1 && (
              <p style={{ marginRight: 5 }}>{"•"}</p>
            )}
            <p>
              {foreignKeyValues?.[id]?.[item]?.view.length > 30
                ? foreignKeyValues?.[id]?.[item]?.view.slice(0, 30) + "..."
                : foreignKeyValues?.[id]?.[item]?.view}
              <br />
            </p>
          </Flex>
        ));
      shortNewValue.push(
        <Flex key={`${id}-extra`} direction={"horizontal"}>
          {tempServerValue?.length > 1 && (
            <p style={{ marginRight: 5 }}>{"•"}</p>
          )}
          <p>
            {"..."}
            <br />
          </p>
        </Flex>
      );

      // return shortNewValue;

      return (
        <Tooltip
          color="white"
          overlayInnerStyle={{ color: "black", width: "max-content" }}
          title={title}
          placement="bottom"
        >
          {shortNewValue}
        </Tooltip>
      );
    }

    return (
      <Tooltip
        color="white"
        overlayInnerStyle={{ color: "black", width: "max-content" }}
        title={title}
        placement="bottom"
      >
        {newValue}
      </Tooltip>
    );
  }

  return <p>{htmlCodesRemover(value)}</p>;
};

export default MultipleSelectionType;
