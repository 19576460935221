import { Modal, Button, Checkbox, Spin } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import Flex from "../../../helpers/components/Flex";
import { useCommonTitlesStore, useAuthStore } from "../../../store/useStores";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableBody, TableHead } from "@mui/material";
import apiGetUserProjectRights from "../../../api/endpoints/apiGetUserProjectRights";
import htmlCodesRemover from "../../ultimateTable/helpers/htmlCodesRemover";
import filterProjectsOnSearch from "./helpers/filterProjectsOnSearch";
import ProjectSearch from "./helpers/ProjectSearch";

const ProjectAccessRightsModalContent = ({
  userProjectAccessRights,
  headers,
  updateUserProjectAccessRights,
  allChecked,
  selectAll,
  user,
  changedProjects,
  setChangedProjects,
  updateSearchValue,
}) => {
  return (
    <Flex
      flexDirection={"column"}
      style={{ marginTop: "2rem", marginBottom: "2rem", maxHeight: "65vh" }}
    >
      <h3 style={{ display: "flex", justifyContent: "center" }}>
        {user?.USER_LASTNAME} {user?.USER_FIRSTNAME}
      </h3>
      <Flex
        flexDirection={"row"}
        style={{ width: "100%", justifyContent: "flex-end", marginTop: 15 }}
      >
        <ProjectSearch updateSearchValue={updateSearchValue} />
      </Flex>
      <TableContainer
        component={Paper}
        style={{ maxHeight: "70vh", marginTop: "2rem" }}
        className="testing_table"
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  style={{ fontWeight: 700, color: "rgb(8, 8, 46)" }}
                  key={header.id}
                >
                  <Flex flexDirection={"row"} style={{ gap: "0.5rem" }}>
                    {index !== 0 && (
                      <Checkbox
                        checked={allChecked}
                        onChange={(e) => selectAll(e.target.checked)}
                      />
                    )}
                    {header.title}
                  </Flex>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {userProjectAccessRights.map((item) => (
              <TableRow key={item.ID}>
                {headers.map(({ id }, index) => (
                  <TableCell key={id}>
                    <>
                      {index === 0 ? (
                        <p>{htmlCodesRemover(item[id])}</p>
                      ) : (
                        <Checkbox
                          checked={item[id]}
                          onChange={(e) => {
                            updateUserProjectAccessRights(
                              item.ID,
                              "HASACCESS",
                              e.target.checked
                            );
                            setChangedProjects({
                              ...changedProjects,
                              [item.ID]: e.target.checked,
                            });
                          }}
                        />
                      )}
                    </>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Flex>
  );
};

export const ProjectAccessRightsModal = ({
  visible,
  userID,
  onSave,
  onCancel,
  title,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const baseURL = useAuthStore((state) => state.baseURL);
  const language = useAuthStore((state) => state.language);
  const projectID = useAuthStore((state) => state.projectID);
  const [userProjectAccessRights, setUserProjectAccessRights] = useState([]);
  const [allData, setAllData] = useState([]);
  const [changedProjects, setChangedProjects] = useState({});
  const [titles, setTitles] = useState({});
  const [user, setUser] = useState({});
  const [headers, setHeaders] = useState([]);
  const [allChecked, setAllChecked] = useState(false);

  useEffect(async () => {
    const { projects, moduletitles, user } = await apiGetUserProjectRights(
      baseURL,
      userID
    );
    setUserProjectAccessRights(projects);
    setAllData(projects);
    setTitles(moduletitles);
    setUser(user);
    setHeaders([
      { id: "TITLE", title: moduletitles["FD|275|TITLE"] },
      { id: "HASACCESS", title: moduletitles["FD|276|TITLE"] },
    ]);

    let accessAll = true;
    projects.forEach((item) => {
      if (!item.HASACCESS) {
        accessAll = false;
      }
    });
    setAllChecked(accessAll);
  }, [userID, language, projectID]);

  const updateUserProjectAccessRights = (projectID, id, value) => {
    const newState = userProjectAccessRights.map((item) => {
      if (item.ID === projectID) {
        return { ...item, [id]: value };
      }

      return item;
    });

    setUserProjectAccessRights(newState);
  };

  const selectAll = (value) => {
    const newState = userProjectAccessRights.map((item) => {
      return { ...item, HASACCESS: value };
    });

    setUserProjectAccessRights(newState);
    setAllChecked(value);
  };

  const updateSearchValue = (change) => {
    const tempData = filterProjectsOnSearch(allData, change, headers);

    if (change) {
      const newData = tempData.map((item) => {
        if (Object.keys(changedProjects)?.includes(item.ID)) {
          return { ...item, HASACCESS: changedProjects[item.ID] };
        } else {
          return item;
        }
      });
      setUserProjectAccessRights([...newData]);
    } else {
      const newData = allData.map((item) => {
        if (Object.keys(changedProjects)?.includes(item.ID)) {
          return { ...item, HASACCESS: changedProjects[item.ID] };
        } else {
          return item;
        }
      });
      setUserProjectAccessRights([...newData]);
    }
  };

  return (
    <Modal
      key={`userProjectAccessRights-modal`}
      mask={false}
      maskClosable={false}
      open={visible}
      style={{
        minWidth: "60vw",
      }}
      bodyStyle={{ paddingLeft: 80, paddingRight: 80 }}
      title={title}
      onOk={() => onSave(userID, userProjectAccessRights)}
      onCancel={() => onCancel()}
      footer={[
        <Button key="back" onClick={() => onCancel()}>
          {commonTitles["CO|1|CANCELBTN"]}
        </Button>,
        <Button
          key="submit"
          type="primary"
          style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
          onClick={() => onSave(userID, userProjectAccessRights)}
        >
          {commonTitles["CO|1|SAVEBTN"]}
        </Button>,
      ]}
    >
      {userProjectAccessRights.length ? (
        <ProjectAccessRightsModalContent
          userProjectAccessRights={userProjectAccessRights}
          headers={headers}
          updateUserProjectAccessRights={updateUserProjectAccessRights}
          allChecked={allChecked}
          selectAll={selectAll}
          user={user}
          changedProjects={changedProjects}
          setChangedProjects={setChangedProjects}
          updateSearchValue={updateSearchValue}
        />
      ) : (
        <Spin />
      )}
    </Modal>
  );
};
